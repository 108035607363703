<template>
  <div class="person-form-item">
    <div class="form-wrapper">
      <div class="form-item">
        <div class="label">身份类型</div>
        <van-radio-group v-model.trim="idType" class="id-type-radio-group">
          <van-radio name="NATURAL_PERSON">自然人</van-radio>
          <van-radio name="LEGAL_ENTITY">法人</van-radio>
          <van-radio name="NON_LEGAL_ENTITY">非法人</van-radio>
        </van-radio-group>
      </div>
    </div>
    <div class="info-nature" title="自然人" v-if="isActive('NATURAL_PERSON')">
      <van-cell-group id="nuture">
        <van-field
          label="身份证号"
          v-model.trim="formCopy.idNumber"
          input-align="right"
          :error="idNumberErr"
          placeholder="请输入身份证号"
        />
        <van-field
          label="联系电话"
          input-align="right"
          :error="phoneErr"
          v-model.number="formCopy.phone"
          maxlength="12"
          type="digit"
          placeholder="请填写联系电话"
        />
        <div class="form-wrapper">
          <div class="form-item">
            <div class="label">性别</div>
            <van-radio-group
              v-model.trim="formCopy.sex"
              class="sex-type-radio-group"
            >
              <van-radio name="MALE">男</van-radio>
              <van-radio name="FEMALE">女</van-radio>
            </van-radio-group>
          </div>
        </div>
        <van-cell
          title="民族"
          :value="formCopy.nation || '请选择民族'"
          is-link
          @click="pickNation"
        />
        <van-field
          label="住所地"
          input-align="right"
          @focus="autoFillAddress"
          v-model.trim="formCopy.address"
          placeholder="请填写地址"
        />
      </van-cell-group>
    </div>
    <div class="info-legal" title="法人单位" v-if="isActive('LEGAL_ENTITY')">
      <van-cell-group>
        <van-field
          label="统一社会信用代码"
          input-align="right"
          v-model.trim="formCopy.idNumber"
          placeholder="请输入信用代码"
        />
        <div class="multi-row">
          <van-field
            label="法定代表人"
            input-align="right"
            class="legal-name"
            v-model.trim="formCopy.manager"
            placeholder="姓名"
          />
          <van-field
            label="职务"
            input-align="right"
            class="legal-position"
            v-model.trim="formCopy.position"
            placeholder="职务"
          />
        </div>
        <van-field
          label="联系电话"
          input-align="right"
          :error="phoneErr"
          v-model.number="formCopy.phone"
          type="digit"
          maxlength="12"
          placeholder="请填写联系电话"
        />
        <van-field
          label="地址"
          input-align="right"
          v-model.trim="formCopy.address"
          placeholder="请填写地址"
        />
      </van-cell-group>
    </div>
    <div
      class="info-non-legal"
      title="非法人单位"
      v-if="isActive('NON_LEGAL_ENTITY')"
    >
      <van-cell-group>
        <van-field
          label="统一社会信用代码"
          input-align="right"
          v-model.trim="formCopy.idNumber"
          placeholder="请输入信用代码"
        />
        <div class="multi-row">
          <van-field
            label="主要负责人"
            input-align="right"
            class="legal-name"
            v-model.trim="formCopy.manager"
            placeholder="姓名"
          />
          <van-field
            label="职务"
            input-align="right"
            class="legal-position"
            v-model.trim="formCopy.position"
            placeholder="职务"
          />
        </div>
        <van-field
          label="联系电话"
          input-align="right"
          :error="phoneErr"
          v-model.number="formCopy.phone"
          type="digit"
          maxlength="12"
          placeholder="请填写联系电话"
        />
        <van-field
          label="地址"
          input-align="right"
          v-model.trim="formCopy.address"
          placeholder="请填写地址"
        />
      </van-cell-group>
    </div>
    <van-collapse v-model.trim="activeOtherInfo" class="other-info-collapse">
      <van-collapse-item title="其他信息" name="other">
        <van-cell-group v-show="isActive('NATURAL_PERSON')">
          <van-field
            label="文书送达地"
            input-align="right"
            ref="documentDelivery"
            @focus="onDocumentDeliveryFocus"
            @blur="onDocumentDeliveryBlur"
            v-model.trim="formCopy.documentDelivery"
            placeholder="请填写文书送达地"
          />
          <div
            class="form-suggestion"
            v-show="showDocumentDeliverySuggestionWrapper"
          >
            <div
              class="document-delivery-suggestion"
              v-for="(agent, i) in agentList"
              :key="i"
              @click="setDocumentDelivery(agent.address)"
            >
              <div class="address">
                <div class="title">
                  {{ agent.address || '未能获取到代理人地址请手动填写' }}
                </div>
                <div class="label">事务所</div>
              </div>
              <div class="contact">
                <div class="name">{{ agent.name }}</div>
                <div class="phone">
                  {{ agent.phone || '未能获取到手机号码' }}
                </div>
              </div>
            </div>
          </div>

          <van-field
            label="经常居住地"
            input-align="right"
            v-model.trim="formCopy.longResidence"
            @focus="autoFillLongResidence"
            placeholder="请填写经常居住地"
          />
          <van-field
            label="职业"
            input-align="right"
            v-model.trim="formCopy.profession"
            placeholder="请填写职业"
          />
          <van-field
            label="工作单位"
            input-align="right"
            v-model.trim="formCopy.workOffice"
            placeholder="请填写工作单位"
          />
          <van-field
            label="邮政编码"
            input-align="right"
            @focus="autoFillPostalCode"
            v-model.trim="formCopy.postalCode"
            placeholder="请填写邮政编码"
          />
          <van-field
            label="其他电话"
            input-align="right"
            v-model.trim="formCopy.otherPhone"
            placeholder="请填写其他电话"
          />
          <van-field
            label="邮箱"
            input-align="right"
            v-model.trim="formCopy.email"
            placeholder="请填写邮箱"
          />
          <van-field
            label="QQ"
            input-align="right"
            v-model.trim="formCopy.qq"
            placeholder="请填写QQ"
          />
          <van-field
            label="微信"
            input-align="right"
            v-model.trim="formCopy.weChat"
            placeholder="请填写微信"
          />
          <van-field
            label="其他"
            input-align="right"
            v-model.trim="formCopy.memo"
            placeholder="其他信息"
          />
          <div class="custom-field">
            <div class="key">
              <input
                v-model.trim="formCopy.selfColumnName"
                placeholder="自填项名称"
              />
            </div>
            <div class="value">
              <input
                v-model.trim="formCopy.selfColumnValue"
                placeholder="自填项内容"
              />
            </div>
          </div>
        </van-cell-group>
        <van-cell-group v-show="isActive('LEGAL_ENTITY')">
          <van-field
            label="文书送达地"
            input-align="right"
            ref="documentDelivery"
            @focus="onDocumentDeliveryFocus"
            @blur="onDocumentDeliveryBlur"
            v-model.trim="formCopy.documentDelivery"
            placeholder="请填写文书送达地"
          />
          <div
            class="form-suggestion"
            v-show="showDocumentDeliverySuggestionWrapper"
          >
            <div
              class="document-delivery-suggestion"
              v-for="(agent, i) in agentList"
              :key="i"
              @click="setDocumentDelivery(agent.address)"
            >
              <div class="address">
                <div class="title">
                  {{ agent.address || '未能获取到代理人地址请手动填写' }}
                </div>
                <div class="label">事务所</div>
              </div>
              <div class="contact">
                <div class="name">{{ agent.name }}</div>
                <div class="phone">
                  {{ agent.phone || '未能获取到手机号码' }}
                </div>
              </div>
            </div>
          </div>
          <van-field
            label="邮政编码"
            input-align="right"
            v-model.trim="formCopy.postalCode"
            placeholder="请填写邮政编码"
          />
          <van-field
            label="其他电话"
            input-align="right"
            v-model.trim="formCopy.otherPhone"
            placeholder="请填写其他电话"
          />
        </van-cell-group>
        <van-cell-group v-show="isActive('NON_LEGAL_ENTITY')">
          <van-field
            label="文书送达地"
            input-align="right"
            ref="documentDelivery"
            @focus="onDocumentDeliveryFocus"
            @blur="onDocumentDeliveryBlur"
            v-model.trim="formCopy.documentDelivery"
            placeholder="请填写文书送达地"
          />
          <div
            class="form-suggestion"
            v-show="showDocumentDeliverySuggestionWrapper"
          >
            <div
              class="document-delivery-suggestion"
              v-for="(agent, i) in agentList"
              :key="i"
              @click="setDocumentDelivery(agent.address)"
            >
              <div class="address">
                <div class="title">
                  {{ agent.address || '未能获取到代理人地址请手动填写' }}
                </div>
                <div class="label">事务所</div>
              </div>
              <div class="contact">
                <div class="name">{{ agent.name }}</div>
                <div class="phone">
                  {{ agent.phone || '未能获取到手机号码' }}
                </div>
              </div>
            </div>
          </div>
          <van-field
            label="邮政编码"
            input-align="right"
            v-model.trim="formCopy.postalCode"
            placeholder="请填写邮政编码"
          />
          <van-field
            label="其他电话"
            input-align="right"
            v-model.trim="formCopy.otherPhone"
            placeholder="请填写其他电话"
          />
        </van-cell-group>
      </van-collapse-item>
    </van-collapse>
    <div class="agent">
      <div class="sec">
        <div class="title-normal">代理人</div>
        <div class="action" @click.stop="addAgent">
          <i class="iconfont icon-tianjia1"></i>添加代理人
        </div>
      </div>
      <!-- 代理人 -->
      <div class="agent-list">
        <agent-item
          @deleteAgent="handleDeleteAgent"
          v-for="(p, i) in agentList"
          :key="p.refname"
          :index="i"
          :agentData="p"
          :isDefaultAgent="p.isDefaultAgent"
          type="代理人"
          :ref="p.refname"
        />
      </div>
    </div>
    <van-popup
      v-model.trim="showNationPicker"
      position="bottom"
      get-container="body"
    >
      <van-picker
        :columns="nationList"
        :item-height="pickCellheight"
        show-toolbar
        title="选择民族"
        :visible-item-count="5"
        @cancel="onNationCancel"
        @confirm="onNationConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import { caseBase } from '~api-config'
import { mapState } from 'vuex'
import { caseAddRadioData } from '../../const/caseAddCellData'
import { genID } from '@/common/utils'
import AgentItem from '../agentItem'

export default {
  name: 'person-form',
  data() {
    return {
      // 激活的表单类型
      activeCopy: 0,
      formCopy: {
        category: '',
        displayClient: '',
        // 法人或非法人单位名称 或自然人姓名
        name: '',
        sex: '',
        nation: '',
        idNumber: '',
        phone: '',
        address: '',
        selfColumnName: '',
        selfColumnValue: '',
        memo: '',
        profession: '',
        workOffice: '',
        // 管理者姓名
        manager: '',
        // 职务
        position: '',
        longResidence: '',
        weChat: '',
        qq: ''
      },
      // 身份类型 默认为自然人
      idType: 'NATURAL_PERSON',
      // 是否现实民族选择器
      showNationPicker: false,
      // 代理人列表
      agentList: [],
      activeOtherInfo: [''],
      showDocumentDeliverySuggestion: false,
      // 身份证解析后的数据
      idNumberInfo: null
    }
  },
  props: {
    active: String,
    formData: Object,
    isWtr: Boolean,
    clientlength: Boolean
  },
  components: {
    AgentItem
  },
  computed: {
    ...mapState('user', ['userInfo']),
    nationList() {
      const nationArr = []
      caseAddRadioData.nationList.forEach(item => {
        nationArr.push(item.name)
      })
      return nationArr
    },
    pickCellheight() {
      const dpr = document.documentElement.getAttribute('data-dpr') || 1
      return dpr * 38
    },
    idNumber() {
      if (this.formCopy.idNumber) {
        return this.formCopy.idNumber
      } else {
        return ''
      }
    },
    showDocumentDeliverySuggestionWrapper() {
      return this.showDocumentDeliverySuggestion && this.agentList.length
    },
    phoneErr() {
      return !this.checkPhone(this.formCopy.phone)
    },
    idNumberErr() {
      return !this.checkIdNum(this.formCopy.idNumber)
    }
  },
  methods: {
    getAreafromIdCard(idNumber) {
      this.$emit('getAreafromIdCard', idNumber)
    },
    // 判断根据身份类型激活不同表单类型
    isActive(type) {
      if (type === this.idType) {
        return true
      } else {
        return false
      }
    },
    // 选择民族
    pickNation() {
      this.showNationPicker = true
    },
    // 选择民族 - 确定
    onNationConfirm(value, index) {
      this.formCopy.nation = value
      this.showNationPicker = false
    },
    // 选择民族 - 取消
    onNationCancel() {
      this.showNationPicker = false
    },
    // 添加默认代理人
    async addDefaultAgent() {
      // 如果用户个人信息是律师则添加
      const job = this.userInfo.lawyer && this.userInfo.lawyer.jobType
      if (job && job !== '其他') {
        this.$axios
          .get(`${caseBase}/lts/agent/default`)
          .then(res => {
            if (res.data.code === '200') {
              const agent = res.data.data
              // 是否是默认代理律师
              agent.isDefaultAgent = true
              const ref = genID()
              agent.refname = ref
              this.agentList.push(agent)
            }
          })
          .catch(err => {
            console.log(err)
          })
      }
    },
    // 移除默认代理人
    removeDefaultAgent() {
      if (this.agentList.length === 1) {
        this.agentList = []
      } else {
        this.$axios
          .get(`${caseBase}/lts/agent/default`)
          .then(res => {
            if (res.data && res.data.data) {
              this.agentList.forEach((item, index) => {
                if (item.name === res.data.data.name) {
                  this.agentList.splice(index, 1)
                }
              })
            }
          })
          .catch(err => {
            console.log('获取默认当事人接口错误', err)
          })
      }

      // console.log(this.agentList)
    },
    // 添加代理人
    addAgent() {
      console.log(this.agentList, '添加代理人')
      if (this.agentList.length < 2) {
        const ref = genID()
        const p = {
          refname: ref,
          isDefaultAgent: false
        }
        this.agentList.push(p)
      } else {
        this.$toast('1个当事人最多可添加2个代理人')
      }
    },
    // 删除代理人
    handleDeleteAgent(payload) {
      this.$axios.get(`${caseBase}/lts/agent/default`).then(res => {
        // 如果我删除的是代理律师
        if (res.data.data.name === this.agentList[payload].name) {
          // 如果现在没有委托人 必须得有一个委托人
          if (this.clientlength) {
            this.$notify('必须得有一个委托人')
            return false
          } else {
            // 如果有委托人 可以删除代理律师
            this.$emit('changeFlag', false) // 删除的方法是对的，但是删除的时候把状态传给了changeFlag,状态更换可能导致数组全部消失
            // this.$emit('delagent', payload)
            const y = this.agentList
            const nY = y.splice(payload, 1) // 被删除的代理人
            console.log('删除代理人1: ', nY, this.agentList)
          }
        } else {
          // 删除得如果不是代理律师 可以直接删除
          this.$emit('delagent', payload)
          const y = this.agentList
          const nY = y.splice(payload, 1) // 被删除的代理人
          console.log('删除代理人2: ', nY, this.agentList)
        }
      })
    },
    // 验证手机号
    checkPhone(phone) {
      console.log('12333', phone)
      if (phone === '' || phone === null) {
        return true
      } else if (phone === undefined) {
        return false
      } else {
        if (phone.toString().length === 11 || phone.toString().length === 12) {
          return !isNaN(phone)
        } else {
          return false
        }
      }
    },
    // 检查身份证号
    checkIdNum(num) {
      if (this.idType !== 'NATURAL_PERSON') {
        return true
      } else {
        if (num === '' || num === null) {
          return true
        } else if (num === undefined) {
          return false
        } else {
          const rule = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
          return rule.test(num)
        }
      }
    },
    // 获取数据
    getData() {
      const p = this.formCopy
      if (this.formCopy.phone !== '' || this.formCopy.idNumber !== '') {
        const validPhone = this.checkPhone(this.formCopy.phone)
        const validIdNumber = this.checkIdNum(this.formCopy.idNumber)
        if (!validIdNumber) {
          this.$emit('emitErr', '身份证号')
          return null
        } else if (!validPhone) {
          if (this.formCopy.idNumber.length < 11) {
            this.$emit('emitErr', '手机号长度')
          } else {
            this.$emit('emitErr', '手机号')
          }
          return null
        } else {
          if (this.agentList.length) {
            const aList = []
            this.agentList.forEach(item => {
              const agent = this.$refs[item.refname][0].agent
              aList.push(agent)
            })
            p.agentList = aList
          }
          return p
        }
      } else {
        if (this.agentList.length) {
          const aList = []
          this.agentList.forEach(item => {
            const agent = this.$refs[item.refname][0].agent
            aList.push(agent)
          })
          p.agentList = aList
        }
        return p
      }
    },
    // 识别身份证信息
    parseIdNumber() {
      this.$axios
        .get(
          `${caseBase}/new-design/adminstrative/select?idNum=${this.idNumber}`
        )
        .then(res => {
          const { code } = res.data
          if (code === 200) {
            this.idNumberInfo = res.data.data
            const { gender } = this.idNumberInfo
            if (gender) {
              this.formCopy.sex = gender === '男' ? 'MALE' : 'FEMALE'
            }
          } else if (code === 421) {
            this.$notify('您输的身份证信息有误')
          }
        })
        .catch(err => {
          Promise.reject(err)
        })
    },
    onDocumentDeliveryFocus() {
      this.showDocumentDeliverySuggestion = true
    },
    onDocumentDeliveryBlur() {
      this.showDocumentDeliverySuggestion = false
    },
    // 设置文书送达地址
    setDocumentDelivery(addr) {
      if (addr) {
        this.formCopy.documentDelivery = addr
      }
    },
    // 自动补全地址
    autoFillAddress() {
      if (this.idNumberInfo) {
        const { regionBean } = this.idNumberInfo
        const p = regionBean.province ? regionBean.province : ''
        const c = regionBean.city ? regionBean.city : ''
        const d = regionBean.district ? regionBean.district : ''
        const addressHint = `${p}${c}${d}`
        this.formCopy.address = addressHint
      }
    },
    // 自动补全常住地
    autoFillLongResidence() {
      if (this.idNumberInfo) {
        const { regionBean } = this.idNumberInfo
        const p = regionBean.province ? regionBean.province : ''
        const c = regionBean.city ? regionBean.city : ''
        const d = regionBean.district ? regionBean.district : ''
        const addressHint = `${p}${c}${d}`
        this.formCopy.longResidence = addressHint
      }
    },
    // 自动补全邮编
    autoFillPostalCode() {
      if (this.idNumberInfo) {
        this.formCopy.postalCode = this.idNumberInfo.postCode
      }
    }
  },
  created() {
    this.activeCopy = this.active
    this.formCopy = this.formData
    const arr = this.formCopy.agentList
    if (arr) {
      if (arr.length > 0) {
        const aList = []
        arr.forEach(agent => {
          const a = agent
          a.refname = genID()
          a.isDefaultAgent = false
          aList.push(a)
        })
        this.agentList = aList
      } else if (this.isWtr) {
        this.addDefaultAgent()
      }
    } else if (this.isWtr) {
      // 如果没有arr  有iswrt 则调用 代理人方法
      this.addDefaultAgent()
    }
    // 设置代理人类型
    if (this.formData.category) {
      this.idType = this.formData.category
    }
  },
  watch: {
    agentList() {
      var p = {}
      var newthis = this
      setTimeout(function() {
        if (newthis.agentList.length) {
          const aList = []
          newthis.agentList.forEach(item => {
            const agent = newthis.$refs[item.refname][0].agent
            aList.push(agent)
          })
          p.agentList = aList
          newthis.$emit('agint', p)
        }
      }, 1)
    },
    active(nv, ov) {
      this.idType = nv

      // 如果是自然人 则去掉性别和民族表单项
      if (nv !== 'NATURAL_PERSON') {
        this.formCopy.sex = null
        this.formCopy.nation = null
      }
      this.formCopy = this.formData
    },
    isWtr(nv, ov) {
      // 切换委托人 当事人
      if (nv) {
        this.addDefaultAgent()
      } else {
        this.removeDefaultAgent()
      }
    },
    idNumber(nv, ov) {
      // 如果是自然人 则再用户输入完身份证号码的时候调用接口识别性别
      if (this.idType === 'NATURAL_PERSON' && nv) {
        if (nv.length === 18) {
          this.parseIdNumber()
        }
      }
    },
    idType() {
      this.$emit('passid', this.idType)
    },
    formCopy() {
      this.$emit('passformData', this.formCopy)
    }
  }
}
</script>

<style lang="stylus">
@import '~styles/variables.styl'

.person-form-item
  padding-bottom 40px
  .van-cell-group
    background transparent
    .van-cell
      background transparent
      .van-cell__value
        // padding-top 2px
        align-items center
    .van-cell:not(:last-child)::after
      border-bottom 0.026667rem solid #d2d2d2
.agent
  margin-top 10px
  .sec
    padding 10px
    display flex
    justify-content space-between
    align-items center
    .title-normal
      font-size 14px
      padding-left 5px
      color #333
    .action
      text-decoration none
      color #569AF9
      font-size 14px
      i
        font-size 14px
  .agent-list
    margin 0
    padding 0 5px
.multi-row
  display flex
  .legal-name
    width 330px
    padding 10px 0 10px 0.4rem
  .legal-position
    .van-cell__title
      width 36px
      flex none
  .van-cell::after
    content ' '
    position absolute
    pointer-events none
    box-sizing border-box
    left 0.4rem
    right 0
    bottom 0
    -webkit-transform scaleY(0.5)
    transform scaleY(0.5)
    border-bottom 0.026667rem solid #d2d2d2
.other-info-collapse
  .van-cell
    background transparent
    .van-cell__title
      span
        color #569AF9
  .van-collapse-item__wrapper
    .van-collapse-item__content
      background transparent
      padding 2px 10px
      .van-cell
        padding 10px 5px
        .van-cell__title
          span
            color #333
    .custom-field
      display flex
      justify-content space-between
      align-items center
      width 100%
      .key, .value
        width 50%
        input
          width 100%
          background transparent
          height 36px
          border none
          padding 0 5px
          font-size 14px
.form-suggestion
  position absolute
  width 100%
  height 68px
  z-index 10
  background rgba(255, 253, 252, 1)
  &::before
    content ''
    left 0
    right 0
    bottom 0
    height 4px
    position absolute
    background -webkit-repeating-linear-gradient(135deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%)
    background repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%)
    background-size 80px
  .document-delivery-suggestion
    height 68px
    padding 6px 10px
    position relative
    box-shadow 0px 6px 10px 0px rgba(144, 138, 138, 0.58)
    .address
      display flex
      align-items center
      height 30px
      .title
        flex 1
        font-weight bold
        color #666
        height 20px
        font-size 14px
      .label
        border-radius 10px
        width 48px
        height 20px
        background #EA8400
        color #fff
        text-align center
        line-height 20px
        font-size 12px
    .contact
      display flex
      align-items center
      height 26px
      .phone
        flex 1
        margin-left 10px
.id-type-radio-group, .sex-type-radio-group
  display flex
  padding 5px 0
  justify-content space-between
  align-items center
  .van-radio
    display flex
    justify-content center
    align-items center
    .van-radio__input
      font-size 13px
      height 13px
      padding-left 1px
      box-sizing border-box
    .van-radio__label
      display block
      margin-left 3px
      font-size 13px
  .van-radio .van-icon-checked
    color #2781FD
</style>
