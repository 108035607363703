<template>
  <div class="agent-item-wrapper">
    <van-action-sheet
      v-model="showAgentPicker"
      class="action-sheet"
      get-container="body"
    >
      <van-picker
        :columns="agentList"
        :item-height="normalPickerItemHeight"
        show-toolbar
        title="选择代理人"
        :visible-item-count="5"
        @cancel="onAgentCancel"
        @confirm="onAgentConfirm"
        :loading="isLoadingAgent"
      />
    </van-action-sheet>
    <van-action-sheet
      v-model="showAgencyTypePicker"
      class="action-sheet"
      get-container="body"
    >
      <van-picker
        :columns="clientTypeList"
        :item-height="normalPickerItemHeight"
        show-toolbar
        title="选择代理权限"
        :visible-item-count="3"
        @cancel="onAgencyTypeCancel"
        @confirm="onAgencyTypeConfirm"
      />
    </van-action-sheet>
    <van-action-sheet
      v-model="isShowAgencyTypePicker"
      class="action-sheet"
      get-container="body"
    >
      <van-picker
        :columns="agencyTypeList"
        :item-height="normalPickerItemHeight"
        show-toolbar
        title="选择代理人类型"
        :visible-item-count="5"
        @cancel="isShowAgencyTypePicker = false"
        @confirm="onTypeConfirm"
      />
    </van-action-sheet>
    <van-action-sheet
      v-model="showNationPicker"
      class="action-sheet"
      get-container="body"
    >
      <van-picker
        :columns="nationList"
        :item-height="normalPickerItemHeight"
        show-toolbar
        title="选择民族"
        :visible-item-count="5"
        @cancel="showNationPicker = false"
        @confirm="onNationConfirm"
      />
    </van-action-sheet>
    <van-popup
      v-model="showAgentPop"
      position="bottom"
      class="agent-pop"
      get-container="body"
      :close-on-click-overlay="laweroffice ? false : true"
    >
      <van-cell-group class="text-right">
        <van-cell
          title="代理人类型"
          is-link
          :value="agencyTypeName || '请选择代理人类型'"
          @click="isShowAgencyTypePicker = true"
        />
        <!-- <van-field v-model="agent.lawFirmName"
                   label="代理人单位"
                   placeholder="代理人单位" /> -->
        <van-cell class="lawfirm-cell" v-show="isShowLawFirm">
          <template slot="title">
            <div class="title-with-switch">
              <div class="label">代理人单位</div>
              <div class="input">
                <input
                  v-model="agent.lawFirmName"
                  placeholder="代理人单位"
                  class="name-input"
                  @focus="onFocus"
                  @blur="onBlur"
                />
                <!-- <i class="iconfont icon-clear-2 name-input-clear"
                   v-show="showSuggestion"
                   @click="clearName"></i> -->
              </div>
            </div>
          </template>
        </van-cell>
        <div class="search-suggestion" v-show="showSuggestion">
          <van-loading v-show="searching" />
          <div
            class="suggestion-list-item"
            v-for="p in lawFirmList"
            :key="p.id"
            @click="selectLawFirm(p)"
          >
            <div class="name">{{ p.name }}</div>
            <div class="attr"></div>
          </div>
        </div>
        <van-field
          v-model="agent.name"
          v-show="isVisiable"
          label="姓名"
          placeholder="请填写代理人姓名"
        />
        <van-cell
          title="代理人姓名"
          v-show="!isVisiable && agent.lawFirmName"
          is-link
          :value="agent.name || '请选择代理人'"
          @click="pickAgent"
        />
        <div class="form-wrapper">
          <div class="form-item">
            <div class="label">代理权限</div>
            <van-radio-group v-model="agent.agencyAuthority">
              <van-radio name="COMMONLY_AGENT">一般授权</van-radio>
              <van-radio name="SPECIAL_AGENT">特别授权</van-radio>
            </van-radio-group>
          </div>
        </div>
        <div class="other-form" v-show="isVisiable">
          <van-field
            v-model="agent.relationship"
            label="与委托人关系"
            placeholder="与委托人关系"
          />
          <van-field
            v-model="agent.idNumber"
            label="身份证号"
            placeholder="请输入身份证号"
          />
          <van-field
            v-model="agent.phone"
            label="联系电话"
            placeholder="请输入联系电话"
          />

          <div class="form-wrapper">
            <div class="form-item">
              <div class="label">性别</div>
              <van-radio-group v-model="agent.sex">
                <van-radio name="MALE">男</van-radio>
                <van-radio name="FEMALE">女</van-radio>
              </van-radio-group>
            </div>
          </div>
          <van-cell
            title="民族"
            :value="agent.nation || '请选择民族'"
            is-link
            @click="pickNation"
          />
          <van-field
            v-model="agent.address"
            label="住所"
            placeholder="请输入住所"
          />
          <van-field
            v-model="agent.workOffice"
            label="工作单位"
            placeholder="请输入工作单位"
          />
        </div>
      </van-cell-group>
      <div class="foot">
        <!-- <div class="back"
             @click="showAgentPop = false">返回</div> -->
        <div class="confirm" @click="hideshow">确定</div>
      </div>
    </van-popup>
    <van-swipe-cell
      :right-width="swipeCellRightWidth"
      class="agent-swiper-cell"
    >
      <van-cell>
        <template slot="title">
          <div class="agent-item">
            <div class="name">{{ agentName }}</div>
            <div class="AgencyType">{{ clientTypeName || '选择代理权限' }}</div>
            <div class="action" @click.stop="editAgent">
              <i class="iconfont icon-edit"></i>
            </div>
          </div>
        </template>
      </van-cell>
      <span slot="right" @click="deleteMe('1')">删除</span>
    </van-swipe-cell>
  </div>
</template>

<script>
import { caseBase } from '~api-config'

import { caseAddRadioData } from '../../const/caseAddCellData'
import { paramCode } from '../../const'

import { mapState } from 'vuex'

export default {
  name: 'agent-item',
  props: {
    index: Number,
    type: String,
    agentData: Object,
    isDefaultAgent: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      list: [],
      // 显示代理人选择器
      showAgentPicker: false,
      // 是否正在加载代理人
      isLoadingAgent: false,
      // 显示代理权限选择器
      showAgencyTypePicker: false,
      // 代理人
      agent: {
        agentId: null,
        caseId: null,
        litigantId: null,
        name: null,
        sex: null,
        agencyAuthority: 'SPECIAL_AGENT',
        agencyType: 'LAWYER',
        identity: null,
        displayLawyerSelf: null,
        leadPhone: null,
        idNumber: null,
        lawyerId: null,
        lawfirmId: null,
        displayLegalAgent: null,
        birthday: null,
        profession: null,
        address: null,
        phone: null,
        relationship: null,
        age: null,
        nation: null,
        workOffice: null,
        relationId: null
      },
      // 代理人列表
      agentList: [],
      // 代理权限
      clientTypeList: [
        { text: '一般授权', enum: 'COMMONLY_AGENT' },
        { text: '特别授权', enum: 'SPECIAL_AGENT' }
      ],
      // 代理人身份
      agencyTypeList: [
        { text: '律师', enum: 'LAWYER' },
        { text: '法律工作者', enum: 'LEGAL_WORKER' },
        { text: '普通公众', enum: 'OTHER_NATURAL_PEOPLE' },
        { text: '负责人（机构)', enum: 'PERSON_IN_CHARGE' },
        { text: '法定代理人', enum: 'LEGAL_AGENT' }
      ],
      showAgentPop: false,
      isShowAgencyTypePicker: false,
      showNationPicker: false,
      // 显示律所搜索建议
      showSuggestion: false,
      // 是否正在搜索
      searching: false,
      // 律所列表
      lawFirmList: [],
      laweroffice: false
    }
  },
  computed: {
    ...mapState('user', ['userInfo']),
    swipeCellRightWidth() {
      const dpr = document.documentElement.getAttribute('data-dpr') || 1
      return dpr * 65
    },
    normalPickerItemHeight() {
      const dpr = document.documentElement.getAttribute('data-dpr') || 1
      return dpr * 38
    },
    nationList() {
      const nationArr = []
      // console.log(caseAddRadioData, '++++++')
      caseAddRadioData.nationList.forEach(item => {
        nationArr.push(item.name)
      })
      // console.log(caseAddRadioData, '-----')
      return nationArr
    },
    // 代理权限名称
    clientTypeName() {
      if (this.agent.agencyAuthority === 'COMMONLY_AGENT') {
        return '一般授权'
      } else {
        return '特别授权'
      }
    },
    //  代理人类型
    agencyTypeName() {
      return paramCode.agencyType(this.agent.agencyType)
    },
    // 代理人名称文字
    agentName() {
      const userName = this.userInfo.nickName
      if (this.agent.name === userName) {
        return this.agent.name + ' (本用户)'
      } else {
        return this.agent.name ? this.agent.name : '代理人姓名'
      }
    },
    // 其他表单项是否显示
    isVisiable() {
      if (
        this.agent.agencyType === 'LAWYER' ||
        this.agent.agencyType === 'LEGAL_WORKER'
      ) {
        return false
      } else {
        return true
      }
    },
    // 代理人单位 律师名称
    lawFirmName() {
      return this.agent.lawFirmName
    },
    // 代理人单位表单是否显示
    isShowLawFirm() {
      return !this.isVisiable
    }
  },
  methods: {
    // 信息页面确定的时候判断是否为同一律所
    hideshow() {
      if (this.$route.params.caseId) {
        this.$axios
          .get(
            `${caseBase}/lts/inf/infOverview?caseId=${
              this.$route.params.caseId
            }`
          )
          .then(res => {
            this.list = res.data.data.litigantList
            const index = this.list
              .filter(item => {
                return item.procedureSubject !== this.$route.params.type
              })
              .findIndex(itemr => {
                for (let i = 0; i < itemr.agentList.length; i++) {
                  if (
                    itemr.agentList[i].lawFirmName === this.agent.lawFirmName
                  ) {
                    return true
                  }
                }
              })
            if (index > -1) {
              this.$notify(
                this.$route.params.type === 'PLAINTIFF'
                  ? '不能与被告为同一所律所'
                  : '不能与原告为同一所律所'
              )
              this.laweroffice = true
            } else {
              this.showAgentPop = false
            }
          })
      } else {
        this.showAgentPop = false
      }
    },
    // 选择代理人
    pickAgent() {
      this.showAgentPicker = true
      this.isLoadingAgent = true
      this.getAllLawyer(this.agent.lawfirmId)
    },
    // 确认选择代理人
    onAgentConfirm(value, index) {
      this.showAgentPicker = false
      this.agent.name = value.text
      this.agent.lawyerId = value.id
    },
    // 选择民族 - 取消
    onAgentCancel() {
      this.showAgentPicker = false
    },
    // 选择代理权限
    pickAgencyType() {
      this.showAgencyTypePicker = true
    },
    // 确认代理类型
    onAgencyTypeConfirm(value, index) {
      this.agent.agencyAuthority = value.enum
      this.showAgencyTypePicker = false
    },
    // 选择民族 - 取消
    onAgencyTypeCancel() {
      this.showAgencyTypePicker = false
    },
    // 编辑代理人
    editAgent() {
      console.log('编辑代理人')
      this.showAgentPop = true
    },
    // 选择代理人类型 - 确认
    onTypeConfirm(value, index) {
      this.agent.agencyType = value.enum
      if (value.enum !== 'LAWYER' || value.enum !== 'LEGAL_WORKER') {
        this.agent.lawFirmName = null
        this.agent.lawfirmId = null
        this.agent.name = null
      }
      this.isShowAgencyTypePicker = false
    },
    // 选择民族
    pickNation() {
      this.showNationPicker = true
    },
    // 选择民族 - 确定
    onNationConfirm(value, index) {
      this.agent.nation = value
      this.showNationPicker = false
    },
    // 触发删除
    deleteMe(name) {
      this.$emit('deleteAgent', this.index)
    },
    // 搜索律所
    searchLawFirm() {
      this.searching = true
      this.$axios({
        method: 'get',
        url: `${caseBase}/lts/Lawfirm/queryLawfirm?name=${this.lawFirmName}`
      })
        .then(res => {
          if (res.data.code === '200') {
            this.lawFirmList = res.data.data.items
          }
          this.searching = false
          // this.searching = true
          // setTimeout(() => {
          //   this.searching = false
          // }, 3000)
        })
        .catch(err => {
          this.searching = false
          Promise.reject(err)
        })
    },
    // 代理人单位输入框聚焦
    onFocus() {
      this.showSuggestion = true
    },
    // 代理人单位输入框失焦
    onBlur() {
      setTimeout(() => {
        this.showSuggestion = false
      }, 100)
    },
    // 代理人单位输入框清空
    clearName() {
      this.agent.lawFirmName = ''
    },
    // 代理人单位输入框搜索建议选择律所
    selectLawFirm(f) {
      this.agent.lawFirmName = f.name
      this.agent.lawfirmId = f.id
    },
    // 获取律所下的所有律师
    getAllLawyer(lawFirmId) {
      this.$axios({
        method: 'get',
        url: `${caseBase}/lts/lawyer/queryLawyer?lawFirmId=${lawFirmId}`
      })
        .then(res => {
          if (res.data.code === '200') {
            const aList = []
            if (res.data.data.length) {
              res.data.data.forEach(item => {
                const agent = item
                agent.text = item.lawyerName
                aList.push(agent)
              })
            }
            this.isLoadingAgent = false
            this.agentList = aList
          }
        })
        .catch(err => {
          this.isLoadingAgent = false
          Promise.reject(err)
        })
    }
  },
  created() {
    if (this.agentData && this.agentData.lawyerId) {
      this.agent = this.agentData
      // 设置默认代理人权限为 特别授权
      if (!this.agent.agencyAuthority) {
        this.agent.agencyAuthority = 'SPECIAL_AGENT'
      }
    }
  },
  watch: {
    lawFirmName(nv, ov) {
      if (nv !== '') {
        this.searchLawFirm()
      }
    },
    agent() {
      this.$emit('agent', this.agent)
    }
  }
}
</script>

<style lang="stylus">
.agent-pop
    position fixed
    left 0
    bottom 0
    height calc(100vh - 46px)
    padding 20px
    z-index 9999
    .foot
      display flex
      justify-content space-around
      align-items center
      margin-top 15px
      margin-bottom 10px
      .back
        color #fff
        font-size 15px
        background #999
        border-radius 2px
        padding 2px 18px
        i
          font-size 15px
      .confirm
        color #fff
        background #2781FD
        border-radius 2px
        padding 2px 18px
        font-size 15px
        i
          font-size 15px
    .van-cell-group
      .search-suggestion
        width 100%
.action-sheet
  position fixed
  left 0
  bottom 0
.agent-item-wrapper
  // position relative
  &:not(:last-child)::after
    border-bottom 1px solid #d2d2d2
    content ''
    width 100%
    bottom 0
    position absolute
  .agent-item
    display flex
    color #569AF9
    justify-content space-between
    align-items center
    height 36px
    width 100%
    .name
      font-size 14px
    .AgencyType
      font-size 14px
    .action
      width 16px
      font-size 14px
.agent-swiper-cell
  .van-swipe-cell__wrapper
    .van-cell
      padding 5px
      background transparent
    .van-swipe-cell__right
      color #fff
      font-size 15px
      width 65px
      height 44px
      display inline-block
      text-align center
      line-height 44px
      background-color #f44
      box-sizing border-box
      border none
.lawfirm-cell
  padding-right 0
  .van-cell__title
    .title-with-switch
      .input
        padding-right 5px
        line-height 15px
</style>
