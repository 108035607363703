export const caseAddRadioData = {
  caseTypes: [
    {
      id: 0,
      name: '民事',
      attrKeyId: 10004,
      attrValueId: 10009,
      sub: [
        {
          id: 0,
          name: '一审',
          attrKeyId: 10005,
          attrValueId: 10014,
          sub: [
            {
              id: 0,
              name: '简易程序',
              attrKeyId: 10007,
              attrValueId: 10020,
              sub: [
                {
                  enum: 'PLAINTIFF',
                  name: '原告',
                  attrKeyId: 10006,
                  attrValueId: 10017,
                },
                {
                  enum: 'DEFENDANT',
                  name: '被告',
                  attrKeyId: 10006,
                  attrValueId: 10018,
                },
                {
                  enum: 'THIRD_PARTY',
                  name: '第三人',
                  attrKeyId: 10006,
                  attrValueId: 10019,
                },
                {
                  enum: 'NOT_INVOLVED_CASE',
                  name: '案外人',
                  attrKeyId: 10006,
                  attrValueId: 13249,
                },
              ],
            },
            {
              id: 1,
              name: '普通程序',
              attrKeyId: 10007,
              attrValueId: 10021,
              sub: [
                {
                  enum: 'PLAINTIFF',
                  name: '原告',
                  attrKeyId: 10006,
                  attrValueId: 10017,
                },
                {
                  enum: 'DEFENDANT',
                  name: '被告',
                  attrKeyId: 10006,
                  attrValueId: 10018,
                },
                {
                  enum: 'THIRD_PARTY',
                  name: '第三人',
                  attrKeyId: 10006,
                  attrValueId: 10019,
                },
                {
                  enum: 'NOT_INVOLVED_CASE',
                  name: '案外人',
                  attrKeyId: 10006,
                  attrValueId: 13249,
                },
              ],
            },
            {
              id: 2,
              name: '特别程序',
              attrKeyId: 10007,
              attrValueId: 10022,
              sub: [
                {
                  enum: 'APPLICANT',
                  name: '申请人',
                  attrKeyId: 10006,
                  attrValueId: 13258,
                },
                {
                  enum: 'CLAIMANT',
                  name: '被申请人',
                  attrKeyId: 10006,
                  attrValueId: 13259,
                },
              ],
            },
            {
              id: 3,
              name: '小额诉讼',
              attrKeyId: 10007,
              attrValueId: 12063,
              sub: [
                {
                  enum: 'PLAINTIFF',
                  name: '原告',
                  attrKeyId: 10006,
                  attrValueId: 10017,
                },
                {
                  enum: 'DEFENDANT',
                  name: '被告',
                  attrKeyId: 10006,
                  attrValueId: 10018,
                },
                {
                  enum: 'THIRD_PARTY',
                  name: '第三人',
                  attrKeyId: 10006,
                  attrValueId: 10019,
                },
                {
                  enum: 'NOT_INVOLVED_CASE',
                  name: '案外人',
                  attrKeyId: 10006,
                  attrValueId: 13249,
                },
              ],
            },
          ],
        },
        {
          id: 1,
          name: '二审',
          attrKeyId: 10005,
          attrValueId: 10015,
          sub: [
            {
              id: 0,
              name: '普通程序',
              attrKeyId: 10007,
              attrValueId: 10021,
              sub: [
                {
                  enum: 'APPELLANT',
                  name: '上诉人',
                  attrKeyId: 10006,
                  attrValueId: 13250,
                },
                {
                  enum: 'APPELLEE',
                  name: '被上诉人',
                  attrKeyId: 10006,
                  attrValueId: 13251,
                },
                {
                  enum: 'FIRST_TRIAL_PLAINTIFF',
                  name: '原审原告',
                  attrKeyId: 10006,
                  attrValueId: 13252,
                },
                {
                  enum: 'FIRST_TRIAL_DEFENDANT',
                  name: '原审被告',
                  attrKeyId: 10006,
                  attrValueId: 13253,
                },
                {
                  enum: 'FIRST_TRIAL_THIRD_PARTY',
                  name: '原审第三人',
                  attrKeyId: 10006,
                  attrValueId: 13254,
                },
              ],
            },
          ],
        },
        {
          id: 2,
          name: '再审',
          attrKeyId: 10005,
          attrValueId: 10016,
          sub: [
            {
              id: 0,
              name: '普通程序',
              attrKeyId: 10007,
              attrValueId: 10021,
              sub: [
                {
                  enum: 'GRIEVANT',
                  name: '申诉人',
                  attrKeyId: 10006,
                  attrValueId: 13256,
                },
                {
                  enum: 'RESPONDENT',
                  name: '被申诉人',
                  attrKeyId: 10006,
                  attrValueId: 13257,
                },
                {
                  enum: 'APPLICANT',
                  name: '申请人',
                  attrKeyId: 10006,
                  attrValueId: 13258,
                },
                {
                  enum: 'CLAIMANT',
                  name: '被申请人',
                  attrKeyId: 10006,
                  attrValueId: 13259,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 1,
      name: '刑事',
      attrKeyId: 10004,
      attrValueId: 10010,
      sub: [
        {
          id: 0,
          name: '一审',
          attrKeyId: 10005,
          attrValueId: 10014,
          sub: [
            {
              id: 0,
              name: '普通程序',
              attrKeyId: 10007,
              attrValueId: 10021,
              sub: [
                {
                  enum: 'PLAINTIFF',
                  name: '原告',
                  attrKeyId: 10006,
                  attrValueId: 10017,
                },
                {
                  enum: 'DEFENDANT',
                  name: '被告',
                  attrKeyId: 10006,
                  attrValueId: 10018,
                },
                {
                  enum: 'THIRD_PARTY',
                  name: '第三人',
                  attrKeyId: 10006,
                  attrValueId: 10019,
                },
              ],
            },
          ],
        },
        {
          id: 1,
          name: '二审',
          attrKeyId: 10005,
          attrValueId: 10015,
          sub: [
            {
              id: 1,
              name: '普通程序',
              attrKeyId: 10007,
              attrValueId: 10021,
              sub: [
                {
                  enum: 'APPELLANT',
                  name: '上诉人',
                  attrKeyId: 10006,
                  attrValueId: 13250,
                },
                {
                  enum: 'APPELLEE',
                  name: '被上诉人',
                  attrKeyId: 10006,
                  attrValueId: 13251,
                },
                {
                  enum: 'FIRST_TRIAL_PLAINTIFF',
                  name: '原审原告',
                  attrKeyId: 10006,
                  attrValueId: 13252,
                },
                {
                  enum: 'FIRST_TRIAL_DEFENDANT',
                  name: '原审被告',
                  attrKeyId: 10006,
                  attrValueId: 13253,
                },
                {
                  enum: 'FIRST_TRIAL_THIRD_PARTY',
                  name: '原审第三人',
                  attrKeyId: 10006,
                  attrValueId: 13254,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 2,
      name: '行政',
      attrKeyId: 10004,
      attrValueId: 10011,
      sub: [
        {
          id: 0,
          name: '一审',
          attrKeyId: 10005,
          attrValueId: 10014,
          sub: [
            {
              id: 0,
              name: '简易程序',
              attrKeyId: 10007,
              attrValueId: 10020,
              sub: [
                {
                  enum: 'PLAINTIFF',
                  name: '原告',
                  attrKeyId: 10006,
                  attrValueId: 10017,
                },
                {
                  enum: 'DEFENDANT',
                  name: '被告',
                  attrKeyId: 10006,
                  attrValueId: 10018,
                },
                {
                  enum: 'THIRD_PARTY',
                  name: '第三人',
                  attrKeyId: 10006,
                  attrValueId: 10019,
                },
                {
                  enum: 'NOT_INVOLVED_CASE',
                  name: '案外人',
                  attrKeyId: 10006,
                  attrValueId: 13249,
                },
              ],
            },
            {
              id: 1,
              name: '普通程序',
              attrKeyId: 10007,
              attrValueId: 10021,
              sub: [
                {
                  enum: 'PLAINTIFF',
                  name: '原告',
                  attrKeyId: 10006,
                  attrValueId: 10017,
                },
                {
                  enum: 'DEFENDANT',
                  name: '被告',
                  attrKeyId: 10006,
                  attrValueId: 10018,
                },
                {
                  enum: 'THIRD_PARTY',
                  name: '第三人',
                  attrKeyId: 10006,
                  attrValueId: 10019,
                },
                {
                  enum: 'NOT_INVOLVED_CASE',
                  name: '案外人',
                  attrKeyId: 10006,
                  attrValueId: 13249,
                },
              ],
            },
          ],
        },
        {
          id: 1,
          name: '二审',
          attrKeyId: 10005,
          attrValueId: 10015,
          sub: [
            {
              id: 0,
              name: '普通程序',
              attrKeyId: 10007,
              attrValueId: 10021,
              sub: [
                {
                  enum: 'APPELLANT',
                  name: '上诉人',
                  attrKeyId: 10006,
                  attrValueId: 13250,
                },
                {
                  enum: 'APPELLEE',
                  name: '被上诉人',
                  attrKeyId: 10006,
                  attrValueId: 13251,
                },
                {
                  enum: 'FIRST_TRIAL_PLAINTIFF',
                  name: '原审原告',
                  attrKeyId: 10006,
                  attrValueId: 13252,
                },
                {
                  enum: 'FIRST_TRIAL_DEFENDANT',
                  name: '原审被告',
                  attrKeyId: 10006,
                  attrValueId: 13253,
                },
                {
                  enum: 'FIRST_TRIAL_THIRD_PARTY',
                  name: '原审第三人',
                  attrKeyId: 10006,
                  attrValueId: 13254,
                },
              ],
            },
          ],
        },
        {
          id: 2,
          name: '再审',
          attrKeyId: 10005,
          attrValueId: 10016,
          sub: [
            {
              id: 1,
              name: '普通程序',
              attrKeyId: 10007,
              attrValueId: 10021,
              sub: [
                {
                  enum: 'GRIEVANT',
                  name: '申诉人',
                  attrKeyId: 10006,
                  attrValueId: 13256,
                },
                {
                  enum: 'RESPONDENT',
                  name: '被申诉人',
                  attrKeyId: 10006,
                  attrValueId: 13257,
                },
                {
                  enum: 'APPLICANT',
                  name: '申请人',
                  attrKeyId: 10006,
                  attrValueId: 13258,
                },
                {
                  enum: 'CLAIMANT',
                  name: '被申请人',
                  attrKeyId: 10006,
                  attrValueId: 13259,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 3,
      name: '执行',
      attrKeyId: 10004,
      attrValueId: 10012,
      sub: [
        {
          id: 4,
          name: '执行',
          attrKeyId: 10005,
          attrValueId: 12064,
          sub: [
            {
              id: 4,
              name: '执行程序',
              attrKeyId: 10007,
              attrValueId: 12062,
              sub: [
                {
                  enum: 'EXECUTION_APPLICANT',
                  name: '申请执行人',
                  attrKeyId: 10006,
                  attrValueId: 13266,
                },
                {
                  enum: 'EXECUTEE',
                  name: '被执行人',
                  attrKeyId: 10006,
                  attrValueId: 13267,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 4,
      name: '交通事故',
      attrKeyId: 10004,
      attrValueId: 10013,
      sub: [
        {
          id: 1,
          name: '一审',
          attrKeyId: 10005,
          attrValueId: 10014,
          sub: [
            {
              id: 0,
              name: '简易程序',
              attrKeyId: 10007,
              attrValueId: 10020,
              sub: [
                {
                  enum: 'DRIVER',
                  name: '驾驶人',
                  attrKeyId: 10006,
                  attrValueId: 13261,
                },
                {
                  enum: 'CAR_OWNER',
                  name: '车主',
                  attrKeyId: 10006,
                  attrValueId: 13262,
                },
                {
                  enum: 'VICTIM',
                  name: '受害人',
                  attrKeyId: 10006,
                  attrValueId: 13263,
                },
                {
                  enum: 'INSURANCE_COMPANY',
                  name: '保险公司',
                  attrKeyId: 10006,
                  attrValueId: 13264,
                },
                {
                  enum: 'PASSENGER',
                  name: '乘车人',
                  attrKeyId: 10006,
                  attrValueId: 13265,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 5,
      name: '劳动仲裁',
      attrKeyId: 10004,
      attrValueId: 13246,
      sub: [
        {
          id: 1,
          name: '一审',
          attrKeyId: 10005,
          attrValueId: 10014,
          sub: [
            {
              id: 0,
              name: '简易程序',
              attrKeyId: 10007,
              attrValueId: 10020,
              sub: [
                {
                  enum: 'APPLICANT',
                  name: '申请人',
                  attrKeyId: 10006,
                  attrValueId: 13258,
                },
                {
                  enum: 'CLAIMANT',
                  name: '被申请人',
                  attrKeyId: 10006,
                  attrValueId: 13259,
                },
                {
                  enum: 'THIRD_PARTY',
                  name: '第三人',
                  attrKeyId: 10006,
                  attrValueId: 10019,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 6,
      name: '行政复议',
      attrKeyId: 10004,
      attrValueId: 13247,
      sub: [
        {
          id: 1,
          name: '一审',
          attrKeyId: 10005,
          attrValueId: 10014,
          sub: [
            {
              id: 0,
              name: '简易程序',
              attrKeyId: 10007,
              attrValueId: 10020,
              sub: [
                {
                  enum: 'APPLICANT',
                  name: '申请人',
                  attrKeyId: 10006,
                  attrValueId: 13258,
                },
                {
                  enum: 'CLAIMANT',
                  name: '被申请人',
                  attrKeyId: 10006,
                  attrValueId: 13259,
                },
                {
                  enum: 'THIRD_PARTY',
                  name: '第三人',
                  attrKeyId: 10006,
                  attrValueId: 10019,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 7,
      name: '商业仲裁',
      attrKeyId: 10004,
      attrValueId: 13248,
      sub: [
        {
          id: 1,
          name: '一审',
          attrKeyId: 10005,
          attrValueId: 10014,
          sub: [
            {
              id: 0,
              name: '简易程序',
              attrKeyId: 10007,
              attrValueId: 10020,
              sub: [
                {
                  enum: 'APPLICANT',
                  name: '申请人',
                  attrKeyId: 10006,
                  attrValueId: 13258,
                },
                {
                  enum: 'CLAIMANT',
                  name: '被申请人',
                  attrKeyId: 10006,
                  attrValueId: 13259,
                },
                {
                  enum: 'THIRD_PARTY',
                  name: '第三人',
                  attrKeyId: 10006,
                  attrValueId: 10019,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  caseTypesV2: [
    {
      id: 10123,
      name: '民事',
      attrKeyId: 10004,
      attrValueId: 10009,
      sub: [
        {
          id: 0,
          name: '一审',
          attrKeyId: 10005,
          attrValueId: 10014,
          sub: [
            {
              id: 0,
              name: '简易程序',
              attrKeyId: 10007,
              attrValueId: 10020,
              sub: [
                {
                  enum: 'PLAINTIFF',
                  name: '原告',
                  attrKeyId: 10006,
                  attrValueId: 10017,
                },
                {
                  enum: 'DEFENDANT',
                  name: '被告',
                  attrKeyId: 10006,
                  attrValueId: 10018,
                },
                {
                  enum: 'THIRD_PARTY',
                  name: '第三人',
                  attrKeyId: 10006,
                  attrValueId: 10019,
                },
                {
                  enum: 'NOT_INVOLVED_CASE',
                  name: '案外人',
                  attrKeyId: 10006,
                  attrValueId: 13249,
                },
              ],
            },
            {
              id: 1,
              name: '普通程序',
              attrKeyId: 10007,
              attrValueId: 10021,
              sub: [
                {
                  enum: 'PLAINTIFF',
                  name: '原告',
                  attrKeyId: 10006,
                  attrValueId: 10017,
                },
                {
                  enum: 'DEFENDANT',
                  name: '被告',
                  attrKeyId: 10006,
                  attrValueId: 10018,
                },
                {
                  enum: 'THIRD_PARTY',
                  name: '第三人',
                  attrKeyId: 10006,
                  attrValueId: 10019,
                },
                {
                  enum: 'NOT_INVOLVED_CASE',
                  name: '案外人',
                  attrKeyId: 10006,
                  attrValueId: 13249,
                },
              ],
            },
            {
              id: 2,
              name: '特别程序',
              attrKeyId: 10007,
              attrValueId: 10022,
              sub: [
                {
                  enum: 'APPLICANT',
                  name: '申请人',
                  attrKeyId: 10006,
                  attrValueId: 13258,
                },
                {
                  enum: 'CLAIMANT',
                  name: '被申请人',
                  attrKeyId: 10006,
                  attrValueId: 13259,
                },
              ],
            },
            {
              id: 3,
              name: '小额诉讼',
              attrKeyId: 10007,
              attrValueId: 12063,
              sub: [
                {
                  enum: 'PLAINTIFF',
                  name: '原告',
                  attrKeyId: 10006,
                  attrValueId: 10017,
                },
                {
                  enum: 'DEFENDANT',
                  name: '被告',
                  attrKeyId: 10006,
                  attrValueId: 10018,
                },
                {
                  enum: 'THIRD_PARTY',
                  name: '第三人',
                  attrKeyId: 10006,
                  attrValueId: 10019,
                },
                {
                  enum: 'NOT_INVOLVED_CASE',
                  name: '案外人',
                  attrKeyId: 10006,
                  attrValueId: 13249,
                },
              ],
            },
          ],
        },
        {
          id: 1,
          name: '二审',
          attrKeyId: 10005,
          attrValueId: 10015,
          sub: [
            {
              id: 0,
              name: '普通程序',
              attrKeyId: 10007,
              attrValueId: 10021,
              sub: [
                {
                  enum: 'APPELLANT',
                  name: '上诉人',
                  attrKeyId: 10006,
                  attrValueId: 13250,
                },
                {
                  enum: 'APPELLEE',
                  name: '被上诉人',
                  attrKeyId: 10006,
                  attrValueId: 13251,
                },
                {
                  enum: 'FIRST_TRIAL_PLAINTIFF',
                  name: '原审原告',
                  attrKeyId: 10006,
                  attrValueId: 13252,
                },
                {
                  enum: 'FIRST_TRIAL_DEFENDANT',
                  name: '原审被告',
                  attrKeyId: 10006,
                  attrValueId: 13253,
                },
                {
                  enum: 'FIRST_TRIAL_THIRD_PARTY',
                  name: '原审第三人',
                  attrKeyId: 10006,
                  attrValueId: 13254,
                },
              ],
            },
          ],
        },
        {
          id: 2,
          name: '再审',
          attrKeyId: 10005,
          attrValueId: 10016,
          sub: [
            {
              id: 0,
              name: '普通程序',
              attrKeyId: 10007,
              attrValueId: 10021,
              sub: [
                {
                  enum: 'GRIEVANT',
                  name: '申诉人',
                  attrKeyId: 10006,
                  attrValueId: 13256,
                },
                {
                  enum: 'RESPONDENT',
                  name: '被申诉人',
                  attrKeyId: 10006,
                  attrValueId: 13257,
                },
                {
                  enum: 'APPLICANT',
                  name: '申请人',
                  attrKeyId: 10006,
                  attrValueId: 13258,
                },
                {
                  enum: 'CLAIMANT',
                  name: '被申请人',
                  attrKeyId: 10006,
                  attrValueId: 13259,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 10968,
      name: '刑事',
      attrKeyId: 10004,
      attrValueId: 10010,
      sub: [
        {
          id: 0,
          name: '侦查阶段',
          attrKeyId: 10005,
          attrValueId: 13272,
          sub: [
            {
              id: 0,
              name: '侦查程序',
              attrKeyId: 10005,
              attrValueId: 13272,
              sub: [
                {
                  enum: 'CRIMINAL_SUSPECT',
                  name: '嫌疑人',
                  attrKeyId: 10006,
                  attrValueId: 13280,
                },
              ],
            },
          ],
        },
        {
          id: 1,
          name: '审查起诉',
          attrKeyId: 10005,
          attrValueId: 13273,
          sub: [
            {
              id: 0,
              name: '审查程序',
              attrKeyId: 10005,
              attrValueId: 13273,
              sub: [
                {
                  enum: 'CRIMINAL_SUSPECT',
                  name: '嫌疑人',
                  attrKeyId: 10006,
                  attrValueId: 13280,
                },
              ],
            },
          ],
        },
        {
          id: 2,
          name: '一审',
          attrKeyId: 10005,
          attrValueId: 10014,
          sub: [
            {
              id: 0,
              name: '一审程序',
              attrKeyId: 10005,
              attrValueId: 10014,
              sub: [
                {
                  enum: 'DEFENDANT_PEOPLE',
                  name: '被告人',
                  attrKeyId: 10006,
                  attrValueId: 13260,
                },
              ],
            },
          ],
        },
        {
          id: 3,
          name: '二审',
          attrKeyId: 10005,
          attrValueId: 10015,
          sub: [
            {
              id: 0,
              name: '二审程序',
              attrKeyId: 10005,
              attrValueId: 10015,
              sub: [
                {
                  enum: 'APPELLANT',
                  name: '上诉人',
                  attrKeyId: 10006,
                  attrValueId: 13250,
                },
                {
                  enum: 'FIRST_TRIAL_DEFENDANT',
                  name: '原审被告',
                  attrKeyId: 10006,
                  attrValueId: 13253,
                },
              ],
            },
          ],
        },
        {
          id: 4,
          name: '再审',
          attrKeyId: 10005,
          attrValueId: 10016,
          sub: [
            {
              id: 0,
              name: '再审程序',
              attrKeyId: 10005,
              attrValueId: 10016,
              sub: [
                {
                  enum: 'RESPONDENT',
                  name: '被申诉人',
                  attrKeyId: 10006,
                  attrValueId: 13257,
                },
                {
                  enum: 'CLAIMANT',
                  name: '被申请人',
                  attrKeyId: 10006,
                  attrValueId: 13259,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 11430,
      name: '行政',
      attrKeyId: 10004,
      attrValueId: 10011,
      sub: [
        {
          id: 0,
          name: '一审',
          attrKeyId: 10005,
          attrValueId: 10014,
          sub: [
            {
              id: 0,
              name: '简易程序',
              attrKeyId: 10007,
              attrValueId: 10020,
              sub: [
                {
                  enum: 'PLAINTIFF',
                  name: '原告',
                  attrKeyId: 10006,
                  attrValueId: 10017,
                },
                {
                  enum: 'DEFENDANT',
                  name: '被告',
                  attrKeyId: 10006,
                  attrValueId: 10018,
                },
                {
                  enum: 'THIRD_PARTY',
                  name: '第三人',
                  attrKeyId: 10006,
                  attrValueId: 10019,
                },
                {
                  enum: 'NOT_INVOLVED_CASE',
                  name: '案外人',
                  attrKeyId: 10006,
                  attrValueId: 13249,
                },
              ],
            },
            {
              id: 1,
              name: '普通程序',
              attrKeyId: 10007,
              attrValueId: 10021,
              sub: [
                {
                  enum: 'PLAINTIFF',
                  name: '原告',
                  attrKeyId: 10006,
                  attrValueId: 10017,
                },
                {
                  enum: 'DEFENDANT',
                  name: '被告',
                  attrKeyId: 10006,
                  attrValueId: 10018,
                },
                {
                  enum: 'THIRD_PARTY',
                  name: '第三人',
                  attrKeyId: 10006,
                  attrValueId: 10019,
                },
                {
                  enum: 'NOT_INVOLVED_CASE',
                  name: '案外人',
                  attrKeyId: 10006,
                  attrValueId: 13249,
                },
              ],
            },
          ],
        },
        {
          id: 1,
          name: '二审',
          attrKeyId: 10005,
          attrValueId: 10015,
          sub: [
            {
              id: 0,
              name: '普通程序',
              attrKeyId: 10007,
              attrValueId: 10021,
              sub: [
                {
                  enum: 'APPELLANT',
                  name: '上诉人',
                  attrKeyId: 10006,
                  attrValueId: 13250,
                },
                {
                  enum: 'APPELLEE',
                  name: '被上诉人',
                  attrKeyId: 10006,
                  attrValueId: 13251,
                },
                {
                  enum: 'FIRST_TRIAL_PLAINTIFF',
                  name: '原审原告',
                  attrKeyId: 10006,
                  attrValueId: 13252,
                },
                {
                  enum: 'FIRST_TRIAL_DEFENDANT',
                  name: '原审被告',
                  attrKeyId: 10006,
                  attrValueId: 13253,
                },
                {
                  enum: 'FIRST_TRIAL_THIRD_PARTY',
                  name: '原审第三人',
                  attrKeyId: 10006,
                  attrValueId: 13254,
                },
              ],
            },
          ],
        },
        {
          id: 2,
          name: '再审',
          attrKeyId: 10005,
          attrValueId: 10016,
          sub: [
            {
              id: 1,
              name: '普通程序',
              attrKeyId: 10007,
              attrValueId: 10021,
              sub: [
                {
                  enum: 'GRIEVANT',
                  name: '申诉人',
                  attrKeyId: 10006,
                  attrValueId: 13256,
                },
                {
                  enum: 'RESPONDENT',
                  name: '被申诉人',
                  attrKeyId: 10006,
                  attrValueId: 13257,
                },
                {
                  enum: 'APPLICANT',
                  name: '申请人',
                  attrKeyId: 10006,
                  attrValueId: 13258,
                },
                {
                  enum: 'CLAIMANT',
                  name: '被申请人',
                  attrKeyId: 10006,
                  attrValueId: 13259,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 11423,
      name: '执行',
      attrKeyId: 10004,
      attrValueId: 10012,
      sub: [
        {
          id: 4,
          name: '执行',
          attrKeyId: 10005,
          attrValueId: 12064,
          sub: [
            {
              id: 0,
              name: '执行程序',
              attrKeyId: 10007,
              attrValueId: 12062,
              sub: [
                {
                  enum: 'EXECUTION_APPLICANT',
                  name: '申请执行人',
                  attrKeyId: 10006,
                  attrValueId: 13266,
                },
                {
                  enum: 'EXECUTEE',
                  name: '被执行人',
                  attrKeyId: 10006,
                  attrValueId: 13267,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 13275,
      name: '法律顾问',
      attrKeyId: 10004,
      attrValueId: 13275,
      litigantType: {
        enum: 'NO_TYPE',
        name: '单位名称',
        attrKeyId: 13282,
        attrValueId: 10006,
      },
      sub: [],
    },
    {
      id: 13276,
      name: '咨询代书',
      attrKeyId: 10004,
      attrValueId: 13276,
      litigantType: {
        enum: 'NO_TYPE',
        name: '委托人',
        attrKeyId: 13282,
        attrValueId: 10006,
      },
      sub: [],
    },
    {
      id: 13277,
      name: '法律援助',
      attrKeyId: 10004,
      attrValueId: 13277,
      litigantType: {
        enum: 'NO_TYPE',
        name: '委托人',
        attrKeyId: 13282,
        attrValueId: 10006,
      },
      sub: [],
    },
    {
      id: 13279,
      name: '其他非诉',
      attrKeyId: 10004,
      attrValueId: 13279,
      litigantType: {
        enum: 'NO_TYPE',
        name: '未分类',
        attrKeyId: 13282,
        attrValueId: 10006,
      },
      sub: [],
    },
    {
      id: 11525,
      name: '交通事故',
      attrKeyId: 10004,
      attrValueId: 11525,
      litigantType: {
        enum: 'NO_TYPE',
        name: '委托人',
        attrKeyId: 13282,
        attrValueId: 10006,
      },
      sub: [],
    },
    {
      id: 11528,
      name: '劳动争议',
      attrKeyId: 10004,
      attrValueId: 11528,
      litigantType: {
        enum: 'NO_TYPE',
        name: '委托人',
        attrKeyId: 13282,
        attrValueId: 10006,
      },
      sub: [],
    },
    {
      id: 11828,
      name: '仲裁纠纷',
      attrKeyId: 10004,
      attrValueId: 11828,
      litigantType: {
        enum: 'NO_TYPE',
        name: '委托人',
        attrKeyId: 13282,
        attrValueId: 10006,
      },
      sub: [],
    },
    {
      id: 13246,
      name: '劳动仲裁',
      attrKeyId: 10004,
      attrValueId: 13246,
      litigantType: {
        enum: 'NO_TYPE',
        name: '委托人',
        attrKeyId: 13282,
        attrValueId: 10006,
      },
      sub: [],
    },
    {
      id: 13247,
      name: '行政复议',
      attrKeyId: 10004,
      attrValueId: 13247,
      litigantType: {
        enum: 'NO_TYPE',
        name: '委托人',
        attrKeyId: 13282,
        attrValueId: 10006,
      },
      sub: [],
    },
    {
      id: 13248,
      name: '商业仲裁',
      attrKeyId: 10004,
      attrValueId: 13248,
      litigantType: {
        enum: 'NO_TYPE',
        name: '委托人',
        attrKeyId: 13282,
        attrValueId: 10006,
      },
      sub: [],
    },
  ],
  nationList: [
    { id: '01', name: '汉族' },
    { id: '02', name: '蒙古族' },
    { id: '03', name: '回族' },
    { id: '04', name: '藏族' },
    { id: '05', name: '维吾尔族' },
    { id: '06', name: '苗族' },
    { id: '07', name: '彝族' },
    { id: '08', name: '壮族' },
    { id: '09', name: '布依族' },
    { id: '10', name: '朝鲜族' },
    { id: '11', name: '满族' },
    { id: '12', name: '侗族' },
    { id: '13', name: '瑶族' },
    { id: '14', name: '白族' },
    { id: '15', name: '土家族' },
    { id: '16', name: '哈尼族' },
    { id: '17', name: '哈萨克族' },
    { id: '18', name: '傣族' },
    { id: '19', name: '黎族' },
    { id: '20', name: '傈僳族' },
    { id: '21', name: '佤族' },
    { id: '22', name: '畲族' },
    { id: '23', name: '高山族' },
    { id: '24', name: '拉祜族' },
    { id: '25', name: '水族' },
    { id: '26', name: '东乡族' },
    { id: '27', name: '纳西族' },
    { id: '28', name: '景颇族' },
    { id: '29', name: '柯尔克孜族' },
    { id: '30', name: '土族' },
    { id: '31', name: '达斡尔族' },
    { id: '32', name: '仫佬族' },
    { id: '33', name: '羌族' },
    { id: '34', name: '布朗族' },
    { id: '35', name: '撒拉族' },
    { id: '36', name: '毛难族' },
    { id: '37', name: '仡佬族' },
    { id: '38', name: '锡伯族' },
    { id: '39', name: '阿昌族' },
    { id: '40', name: '普米族' },
    { id: '41', name: '塔吉克族' },
    { id: '42', name: '怒族' },
    { id: '43', name: '乌孜别克族' },
    { id: '44', name: '俄罗斯族' },
    { id: '45', name: '鄂温克族' },
    { id: '46', name: '崩龙族' },
    { id: '47', name: '保安族' },
    { id: '48', name: '裕固族' },
    { id: '49', name: '京族' },
    { id: '50', name: '塔塔尔族' },
    { id: '51', name: '独龙族' },
    { id: '52', name: '鄂伦春族' },
    { id: '53', name: '赫哲族' },
    { id: '54', name: '门巴族' },
    { id: '55', name: '珞巴族' },
    { id: '56', name: '基诺族' },
  ],
}
