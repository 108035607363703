var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "person-form-item" },
    [
      _c("div", { staticClass: "form-wrapper" }, [
        _c(
          "div",
          { staticClass: "form-item" },
          [
            _c("div", { staticClass: "label" }, [_vm._v("身份类型")]),
            _c(
              "van-radio-group",
              {
                staticClass: "id-type-radio-group",
                model: {
                  value: _vm.idType,
                  callback: function ($$v) {
                    _vm.idType = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "idType",
                },
              },
              [
                _c("van-radio", { attrs: { name: "NATURAL_PERSON" } }, [
                  _vm._v("自然人"),
                ]),
                _c("van-radio", { attrs: { name: "LEGAL_ENTITY" } }, [
                  _vm._v("法人"),
                ]),
                _c("van-radio", { attrs: { name: "NON_LEGAL_ENTITY" } }, [
                  _vm._v("非法人"),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm.isActive("NATURAL_PERSON")
        ? _c(
            "div",
            { staticClass: "info-nature", attrs: { title: "自然人" } },
            [
              _c(
                "van-cell-group",
                { attrs: { id: "nuture" } },
                [
                  _c("van-field", {
                    attrs: {
                      label: "身份证号",
                      "input-align": "right",
                      error: _vm.idNumberErr,
                      placeholder: "请输入身份证号",
                    },
                    model: {
                      value: _vm.formCopy.idNumber,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formCopy,
                          "idNumber",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formCopy.idNumber",
                    },
                  }),
                  _c("van-field", {
                    attrs: {
                      label: "联系电话",
                      "input-align": "right",
                      error: _vm.phoneErr,
                      maxlength: "12",
                      type: "digit",
                      placeholder: "请填写联系电话",
                    },
                    model: {
                      value: _vm.formCopy.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.formCopy, "phone", _vm._n($$v))
                      },
                      expression: "formCopy.phone",
                    },
                  }),
                  _c("div", { staticClass: "form-wrapper" }, [
                    _c(
                      "div",
                      { staticClass: "form-item" },
                      [
                        _c("div", { staticClass: "label" }, [_vm._v("性别")]),
                        _c(
                          "van-radio-group",
                          {
                            staticClass: "sex-type-radio-group",
                            model: {
                              value: _vm.formCopy.sex,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formCopy,
                                  "sex",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "formCopy.sex",
                            },
                          },
                          [
                            _c("van-radio", { attrs: { name: "MALE" } }, [
                              _vm._v("男"),
                            ]),
                            _c("van-radio", { attrs: { name: "FEMALE" } }, [
                              _vm._v("女"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("van-cell", {
                    attrs: {
                      title: "民族",
                      value: _vm.formCopy.nation || "请选择民族",
                      "is-link": "",
                    },
                    on: { click: _vm.pickNation },
                  }),
                  _c("van-field", {
                    attrs: {
                      label: "住所地",
                      "input-align": "right",
                      placeholder: "请填写地址",
                    },
                    on: { focus: _vm.autoFillAddress },
                    model: {
                      value: _vm.formCopy.address,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formCopy,
                          "address",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formCopy.address",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.isActive("LEGAL_ENTITY")
        ? _c(
            "div",
            { staticClass: "info-legal", attrs: { title: "法人单位" } },
            [
              _c(
                "van-cell-group",
                [
                  _c("van-field", {
                    attrs: {
                      label: "统一社会信用代码",
                      "input-align": "right",
                      placeholder: "请输入信用代码",
                    },
                    model: {
                      value: _vm.formCopy.idNumber,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formCopy,
                          "idNumber",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formCopy.idNumber",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "multi-row" },
                    [
                      _c("van-field", {
                        staticClass: "legal-name",
                        attrs: {
                          label: "法定代表人",
                          "input-align": "right",
                          placeholder: "姓名",
                        },
                        model: {
                          value: _vm.formCopy.manager,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formCopy,
                              "manager",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formCopy.manager",
                        },
                      }),
                      _c("van-field", {
                        staticClass: "legal-position",
                        attrs: {
                          label: "职务",
                          "input-align": "right",
                          placeholder: "职务",
                        },
                        model: {
                          value: _vm.formCopy.position,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formCopy,
                              "position",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formCopy.position",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("van-field", {
                    attrs: {
                      label: "联系电话",
                      "input-align": "right",
                      error: _vm.phoneErr,
                      type: "digit",
                      maxlength: "12",
                      placeholder: "请填写联系电话",
                    },
                    model: {
                      value: _vm.formCopy.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.formCopy, "phone", _vm._n($$v))
                      },
                      expression: "formCopy.phone",
                    },
                  }),
                  _c("van-field", {
                    attrs: {
                      label: "地址",
                      "input-align": "right",
                      placeholder: "请填写地址",
                    },
                    model: {
                      value: _vm.formCopy.address,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formCopy,
                          "address",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formCopy.address",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.isActive("NON_LEGAL_ENTITY")
        ? _c(
            "div",
            { staticClass: "info-non-legal", attrs: { title: "非法人单位" } },
            [
              _c(
                "van-cell-group",
                [
                  _c("van-field", {
                    attrs: {
                      label: "统一社会信用代码",
                      "input-align": "right",
                      placeholder: "请输入信用代码",
                    },
                    model: {
                      value: _vm.formCopy.idNumber,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formCopy,
                          "idNumber",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formCopy.idNumber",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "multi-row" },
                    [
                      _c("van-field", {
                        staticClass: "legal-name",
                        attrs: {
                          label: "主要负责人",
                          "input-align": "right",
                          placeholder: "姓名",
                        },
                        model: {
                          value: _vm.formCopy.manager,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formCopy,
                              "manager",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formCopy.manager",
                        },
                      }),
                      _c("van-field", {
                        staticClass: "legal-position",
                        attrs: {
                          label: "职务",
                          "input-align": "right",
                          placeholder: "职务",
                        },
                        model: {
                          value: _vm.formCopy.position,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formCopy,
                              "position",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formCopy.position",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("van-field", {
                    attrs: {
                      label: "联系电话",
                      "input-align": "right",
                      error: _vm.phoneErr,
                      type: "digit",
                      maxlength: "12",
                      placeholder: "请填写联系电话",
                    },
                    model: {
                      value: _vm.formCopy.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.formCopy, "phone", _vm._n($$v))
                      },
                      expression: "formCopy.phone",
                    },
                  }),
                  _c("van-field", {
                    attrs: {
                      label: "地址",
                      "input-align": "right",
                      placeholder: "请填写地址",
                    },
                    model: {
                      value: _vm.formCopy.address,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formCopy,
                          "address",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formCopy.address",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "van-collapse",
        {
          staticClass: "other-info-collapse",
          model: {
            value: _vm.activeOtherInfo,
            callback: function ($$v) {
              _vm.activeOtherInfo = typeof $$v === "string" ? $$v.trim() : $$v
            },
            expression: "activeOtherInfo",
          },
        },
        [
          _c(
            "van-collapse-item",
            { attrs: { title: "其他信息", name: "other" } },
            [
              _c(
                "van-cell-group",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isActive("NATURAL_PERSON"),
                      expression: "isActive('NATURAL_PERSON')",
                    },
                  ],
                },
                [
                  _c("van-field", {
                    ref: "documentDelivery",
                    attrs: {
                      label: "文书送达地",
                      "input-align": "right",
                      placeholder: "请填写文书送达地",
                    },
                    on: {
                      focus: _vm.onDocumentDeliveryFocus,
                      blur: _vm.onDocumentDeliveryBlur,
                    },
                    model: {
                      value: _vm.formCopy.documentDelivery,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formCopy,
                          "documentDelivery",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formCopy.documentDelivery",
                    },
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showDocumentDeliverySuggestionWrapper,
                          expression: "showDocumentDeliverySuggestionWrapper",
                        },
                      ],
                      staticClass: "form-suggestion",
                    },
                    _vm._l(_vm.agentList, function (agent, i) {
                      return _c(
                        "div",
                        {
                          key: i,
                          staticClass: "document-delivery-suggestion",
                          on: {
                            click: function ($event) {
                              return _vm.setDocumentDelivery(agent.address)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "address" }, [
                            _c("div", { staticClass: "title" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    agent.address ||
                                      "未能获取到代理人地址请手动填写"
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("div", { staticClass: "label" }, [
                              _vm._v("事务所"),
                            ]),
                          ]),
                          _c("div", { staticClass: "contact" }, [
                            _c("div", { staticClass: "name" }, [
                              _vm._v(_vm._s(agent.name)),
                            ]),
                            _c("div", { staticClass: "phone" }, [
                              _vm._v(
                                " " +
                                  _vm._s(agent.phone || "未能获取到手机号码") +
                                  " "
                              ),
                            ]),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                  _c("van-field", {
                    attrs: {
                      label: "经常居住地",
                      "input-align": "right",
                      placeholder: "请填写经常居住地",
                    },
                    on: { focus: _vm.autoFillLongResidence },
                    model: {
                      value: _vm.formCopy.longResidence,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formCopy,
                          "longResidence",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formCopy.longResidence",
                    },
                  }),
                  _c("van-field", {
                    attrs: {
                      label: "职业",
                      "input-align": "right",
                      placeholder: "请填写职业",
                    },
                    model: {
                      value: _vm.formCopy.profession,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formCopy,
                          "profession",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formCopy.profession",
                    },
                  }),
                  _c("van-field", {
                    attrs: {
                      label: "工作单位",
                      "input-align": "right",
                      placeholder: "请填写工作单位",
                    },
                    model: {
                      value: _vm.formCopy.workOffice,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formCopy,
                          "workOffice",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formCopy.workOffice",
                    },
                  }),
                  _c("van-field", {
                    attrs: {
                      label: "邮政编码",
                      "input-align": "right",
                      placeholder: "请填写邮政编码",
                    },
                    on: { focus: _vm.autoFillPostalCode },
                    model: {
                      value: _vm.formCopy.postalCode,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formCopy,
                          "postalCode",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formCopy.postalCode",
                    },
                  }),
                  _c("van-field", {
                    attrs: {
                      label: "其他电话",
                      "input-align": "right",
                      placeholder: "请填写其他电话",
                    },
                    model: {
                      value: _vm.formCopy.otherPhone,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formCopy,
                          "otherPhone",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formCopy.otherPhone",
                    },
                  }),
                  _c("van-field", {
                    attrs: {
                      label: "邮箱",
                      "input-align": "right",
                      placeholder: "请填写邮箱",
                    },
                    model: {
                      value: _vm.formCopy.email,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formCopy,
                          "email",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formCopy.email",
                    },
                  }),
                  _c("van-field", {
                    attrs: {
                      label: "QQ",
                      "input-align": "right",
                      placeholder: "请填写QQ",
                    },
                    model: {
                      value: _vm.formCopy.qq,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formCopy,
                          "qq",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formCopy.qq",
                    },
                  }),
                  _c("van-field", {
                    attrs: {
                      label: "微信",
                      "input-align": "right",
                      placeholder: "请填写微信",
                    },
                    model: {
                      value: _vm.formCopy.weChat,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formCopy,
                          "weChat",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formCopy.weChat",
                    },
                  }),
                  _c("van-field", {
                    attrs: {
                      label: "其他",
                      "input-align": "right",
                      placeholder: "其他信息",
                    },
                    model: {
                      value: _vm.formCopy.memo,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formCopy,
                          "memo",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formCopy.memo",
                    },
                  }),
                  _c("div", { staticClass: "custom-field" }, [
                    _c("div", { staticClass: "key" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.formCopy.selfColumnName,
                            expression: "formCopy.selfColumnName",
                            modifiers: { trim: true },
                          },
                        ],
                        attrs: { placeholder: "自填项名称" },
                        domProps: { value: _vm.formCopy.selfColumnName },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.formCopy,
                              "selfColumnName",
                              $event.target.value.trim()
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "value" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.formCopy.selfColumnValue,
                            expression: "formCopy.selfColumnValue",
                            modifiers: { trim: true },
                          },
                        ],
                        attrs: { placeholder: "自填项内容" },
                        domProps: { value: _vm.formCopy.selfColumnValue },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.formCopy,
                              "selfColumnValue",
                              $event.target.value.trim()
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "van-cell-group",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isActive("LEGAL_ENTITY"),
                      expression: "isActive('LEGAL_ENTITY')",
                    },
                  ],
                },
                [
                  _c("van-field", {
                    ref: "documentDelivery",
                    attrs: {
                      label: "文书送达地",
                      "input-align": "right",
                      placeholder: "请填写文书送达地",
                    },
                    on: {
                      focus: _vm.onDocumentDeliveryFocus,
                      blur: _vm.onDocumentDeliveryBlur,
                    },
                    model: {
                      value: _vm.formCopy.documentDelivery,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formCopy,
                          "documentDelivery",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formCopy.documentDelivery",
                    },
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showDocumentDeliverySuggestionWrapper,
                          expression: "showDocumentDeliverySuggestionWrapper",
                        },
                      ],
                      staticClass: "form-suggestion",
                    },
                    _vm._l(_vm.agentList, function (agent, i) {
                      return _c(
                        "div",
                        {
                          key: i,
                          staticClass: "document-delivery-suggestion",
                          on: {
                            click: function ($event) {
                              return _vm.setDocumentDelivery(agent.address)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "address" }, [
                            _c("div", { staticClass: "title" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    agent.address ||
                                      "未能获取到代理人地址请手动填写"
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("div", { staticClass: "label" }, [
                              _vm._v("事务所"),
                            ]),
                          ]),
                          _c("div", { staticClass: "contact" }, [
                            _c("div", { staticClass: "name" }, [
                              _vm._v(_vm._s(agent.name)),
                            ]),
                            _c("div", { staticClass: "phone" }, [
                              _vm._v(
                                " " +
                                  _vm._s(agent.phone || "未能获取到手机号码") +
                                  " "
                              ),
                            ]),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                  _c("van-field", {
                    attrs: {
                      label: "邮政编码",
                      "input-align": "right",
                      placeholder: "请填写邮政编码",
                    },
                    model: {
                      value: _vm.formCopy.postalCode,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formCopy,
                          "postalCode",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formCopy.postalCode",
                    },
                  }),
                  _c("van-field", {
                    attrs: {
                      label: "其他电话",
                      "input-align": "right",
                      placeholder: "请填写其他电话",
                    },
                    model: {
                      value: _vm.formCopy.otherPhone,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formCopy,
                          "otherPhone",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formCopy.otherPhone",
                    },
                  }),
                ],
                1
              ),
              _c(
                "van-cell-group",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isActive("NON_LEGAL_ENTITY"),
                      expression: "isActive('NON_LEGAL_ENTITY')",
                    },
                  ],
                },
                [
                  _c("van-field", {
                    ref: "documentDelivery",
                    attrs: {
                      label: "文书送达地",
                      "input-align": "right",
                      placeholder: "请填写文书送达地",
                    },
                    on: {
                      focus: _vm.onDocumentDeliveryFocus,
                      blur: _vm.onDocumentDeliveryBlur,
                    },
                    model: {
                      value: _vm.formCopy.documentDelivery,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formCopy,
                          "documentDelivery",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formCopy.documentDelivery",
                    },
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showDocumentDeliverySuggestionWrapper,
                          expression: "showDocumentDeliverySuggestionWrapper",
                        },
                      ],
                      staticClass: "form-suggestion",
                    },
                    _vm._l(_vm.agentList, function (agent, i) {
                      return _c(
                        "div",
                        {
                          key: i,
                          staticClass: "document-delivery-suggestion",
                          on: {
                            click: function ($event) {
                              return _vm.setDocumentDelivery(agent.address)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "address" }, [
                            _c("div", { staticClass: "title" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    agent.address ||
                                      "未能获取到代理人地址请手动填写"
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("div", { staticClass: "label" }, [
                              _vm._v("事务所"),
                            ]),
                          ]),
                          _c("div", { staticClass: "contact" }, [
                            _c("div", { staticClass: "name" }, [
                              _vm._v(_vm._s(agent.name)),
                            ]),
                            _c("div", { staticClass: "phone" }, [
                              _vm._v(
                                " " +
                                  _vm._s(agent.phone || "未能获取到手机号码") +
                                  " "
                              ),
                            ]),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                  _c("van-field", {
                    attrs: {
                      label: "邮政编码",
                      "input-align": "right",
                      placeholder: "请填写邮政编码",
                    },
                    model: {
                      value: _vm.formCopy.postalCode,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formCopy,
                          "postalCode",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formCopy.postalCode",
                    },
                  }),
                  _c("van-field", {
                    attrs: {
                      label: "其他电话",
                      "input-align": "right",
                      placeholder: "请填写其他电话",
                    },
                    model: {
                      value: _vm.formCopy.otherPhone,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formCopy,
                          "otherPhone",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formCopy.otherPhone",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "agent" }, [
        _c("div", { staticClass: "sec" }, [
          _c("div", { staticClass: "title-normal" }, [_vm._v("代理人")]),
          _c(
            "div",
            {
              staticClass: "action",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.addAgent.apply(null, arguments)
                },
              },
            },
            [
              _c("i", { staticClass: "iconfont icon-tianjia1" }),
              _vm._v("添加代理人 "),
            ]
          ),
        ]),
        _c(
          "div",
          { staticClass: "agent-list" },
          _vm._l(_vm.agentList, function (p, i) {
            return _c("agent-item", {
              key: p.refname,
              ref: p.refname,
              refInFor: true,
              attrs: {
                index: i,
                agentData: p,
                isDefaultAgent: p.isDefaultAgent,
                type: "代理人",
              },
              on: { deleteAgent: _vm.handleDeleteAgent },
            })
          }),
          1
        ),
      ]),
      _c(
        "van-popup",
        {
          attrs: { position: "bottom", "get-container": "body" },
          model: {
            value: _vm.showNationPicker,
            callback: function ($$v) {
              _vm.showNationPicker = typeof $$v === "string" ? $$v.trim() : $$v
            },
            expression: "showNationPicker",
          },
        },
        [
          _c("van-picker", {
            attrs: {
              columns: _vm.nationList,
              "item-height": _vm.pickCellheight,
              "show-toolbar": "",
              title: "选择民族",
              "visible-item-count": 5,
            },
            on: { cancel: _vm.onNationCancel, confirm: _vm.onNationConfirm },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }