<template>
  <div class="fine-switch">
    <div :class="{wrapper: true, on: active }"
         @click.stop="change"
         :style="switchStyle">
      <div class="node"></div>
      <div class="text">{{switchText}}</div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'fine-switch',
  props: {
    text: String,
    type: Object,
    isWtr: Boolean,
    disabled: Boolean,
    msg: String
  },
  data () {
    return {
      active: false
    }
  },
  computed: {
    ...mapState('createCase', ['wtrType', 'shouldActiveWtrType']),
    switchStyle () {
      if (this.active) {
        return {
          flexDirection: 'row-reverse',
          background: '#FD7A57'
        }
      } else {
        return {
          flexDirection: 'row'
        }
      }
    },
    switchText () {
      if (this.active) {
        return '委托人'
      } else {
        return '当事人'
      }
    }
  },
  methods: {
    ...mapMutations('createCase', ['setWtrType', 'setShouldActiveWtrType']),
    change () {
      if (this.disabled === true) {
        if (this.msg && this.msg !== '') {
          this.$notify(this.msg)
        } else {
          this.$notify('操作不合法')
        }
        return false
      }
      // 判断store里的委托人类型
      if (this.wtrType === undefined || (this.wtrType && this.wtrType === this.type.name)) {
        if (this.active) {
          this.setWtrType(undefined)
        } else {
          this.setWtrType(this.type.name)
        }
        this.active = !this.active
      } else if (this.wtrType && this.wtrType !== this.type.name) {
        this.$notify('您已将委托人身份设置为: ' + this.wtrType)
      }
      this.$emit('setWtr', this.active)
    },
    // 设置为委托人
    setAsWtr () {
      this.active = true
      this.$emit('setWtr', this.active)
      this.setWtrType(this.type.name)
      this.setShouldActiveWtrType(false)
    }
  },
  mounted () {
    // 检查是否需要添加默认代理人
    if (this.shouldActiveWtrType) {
      setTimeout(() => {
        this.setAsWtr()
      }, 300)
    }
  },
  created () {
    if (this.type.name !== this.wtrType) {
      this.active = false
      this.$emit('setWtr', this.active)
      this.setShouldActiveWtrType(false)
    }
    if (this.isWtr) {
      this.setAsWtr()
    }
  },
  watch: {
    isWtr (val) {
      this.active = val
    }
  }
}
</script>

<style lang="stylus" scoped>
.fine-switch
  width 70px
  height 16px
  margin-right 6px
  box-sizing border-box
  border none
  user-select none
  .wrapper
    height 16px
    width 100%
    padding 1px
    display flex
    background #999
    border-radius 8px
    align-items center
    box-sizing border-box
    border none
    .text
      color #ffffff
      font-size 12px
      text-align center
      box-sizing border-box
    .node
      box-sizing border-box
      border-radius 50%
      width 14px
      height 14px
      background #fff
</style>
