<template>
  <div class="person-item">
    <van-swipe-cell :right-width="swipeCellRightWidth" class="person-swiper-cell" :on-close="onDeletePerson">
      <van-cell>
        <template slot="title">
          <div class="title-with-switch">
            <fine-switch text="委托人" :isWtr="isWtr" @setWtr="handleSetWtr" :disabled="clientLength < 2 && isWtr && !($route.params.flag === true)" :msg="'必须有一个委托人'" :type="type" />
            <!-- setWtr 用来点击切换显示律师和隐藏律师 -->
            <input v-model="name" placeholder="请输入姓名/名称" class="name-input" type="text" data-noscroll="YES" @focus="onFocus" @blur="onBlur" />
            <!-- <i class="iconfont icon-clear-2 name-input-clear"
               v-show="showSuggestion"
               @click="clearName"></i> -->
          </div>
        </template>
        <van-icon slot="right-icon" :name="itemCollapseArrow" @click.stop="swiperCellArrowClick" />
      </van-cell>
      <span slot="right">删除</span>
    </van-swipe-cell>
    <div class="search-suggestion" v-show="showSuggestion" v-if="suggestionList.length">
      <van-loading v-show="searching" />
      <div class="suggestion-list-item" v-for="(p, i) in suggestionList" :key="i" @click="select(p)">
        <div class="name">{{ p.companyName || p.litigantName }}</div>
        <div class="attr tag" v-if="p.procedureSubjectShort">
          {{ p.procedureSubjectShort }}
        </div>
      </div>
    </div>
    <div class="collapse-item__wrapper" :style="itemCollapseStyle">
      <div class="collapse-item__content">
        <person-form
          ref="personForm"
          :formData="person"
          @emitErr="handlePhoneErr"
          @passid="passid"
          @passformData="passformData"
          @agint="agent"
          @delagent="delagent"
          @changeFlag="handleSetWtr"
          :isWtr="isWtr"
          :active="personActiveType"
          :clientlength="clientLength < 2 && isWtr && !($route.params.flag === true)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { caseBase } from '~api-config'

import personFormTemplate from '../../const/personFormTemplate.js'

import FineSwitch from '../fineSwitch'
import PersonForm from '../personForm'

export default {
  name: 'person-item',
  props: {
    index: Number,
    type: Object,
    personData: Object,
    finnal: Object,
    clientLength: Number,
    flag: Boolean,
  },
  components: {
    FineSwitch,
    PersonForm,
  },
  data() {
    return {
      // 是否在编辑中
      editing: false,
      // 身份类型激活折叠面板
      activeIdType: [''],
      // 是否正在搜索
      searching: false,
      // 是否显示搜索建议
      showSuggestion: false,
      // 搜索建议列表数据
      suggestionList: [],
      // 身份类型
      idType: '',
      // 身份类型数据
      idTypeList: [
        {
          id: 'NATURAL_PERSON',
          name: '自然人',
        },
        {
          id: 'LEGAL_ENTITY',
          name: '法人',
        },
        {
          id: 'NON_LEGAL_ENTITY',
          name: '非法人',
        },
      ],
      name: '',
      personActiveType: 'NATURAL_PERSON',
      // 当事人表单信息
      person: JSON.parse(JSON.stringify(personFormTemplate)),
      // 是否是代理人
      isWtr: false,
      // 第一次是否要对name进行搜索 用于拍照新建项目
      shouldSearchFirst: true,
      idTypepass: '',
    }
  },
  computed: {
    itemCollapseArrow() {
      if (this.editing) {
        return 'van-icon van-cell__right-icon van-icon-arrow-up'
      } else {
        return 'van-icon van-cell__right-icon van-icon-arrow-down'
      }
    },
    itemCollapseStyle() {
      if (this.editing) {
        return {
          height: '6.4rem',
        }
      } else {
        return {
          height: '0',
        }
      }
    },
    swipeCellRightWidth() {
      const dpr = document.documentElement.getAttribute('data-dpr') || 1
      return parseInt(65 * dpr, 0)
    },
  },
  methods: {
    delagent(index) {
      this.$emit('delagent', index)
    },
    agent(data) {
      this.$emit('agent', data)
    },
    passformData(data) {
      if (this.isWtr) {
        data.displayClient = 'YES'
      } else {
        data.displayClient = 'NO'
      }
      data.name = this.name
      this.$emit('passData', data)
    },
    passid(idType) {
      this.idTypepass = idType
    },
    // 搜索
    search() {
      const name = this.name
      this.searching = true
      // 获取历史案件
      this.$axios
        .get(`${caseBase}/lts/litigant/vagueQueryLitigant?name=${name}`)
        .then((res) => {
          this.searching = false
          if (res.data && res.data.code === '200') {
            this.personActiveType = res.data.data.legalPersonInfo.partiesIdentity || 'NATURAL_PERSON'
            this.suggestionList = res.data.data.onceLitigantList || []
          }
          // 如果搜索词超过三个字 调用工商信息信息接口
          var reg = /[\u4e00-\u9fa5a-zA-Z]+/
          if (this.name && this.name.match(reg) && this.name.match(reg).input && this.name.match(reg).input.length > 3) {
            this.searchCompany()
          } else {
            this.suggestionList = []
          }
        })
        .catch((err) => {
          this.searching = false
          this.suggestionList = []
          Promise.reject(err)
          // this.$notify('获取历史信息失败 请重试')
        })
    },
    searchCompany() {
      this.searching = true
      const name = this.name
      this.$axios
        .get(`${caseBase}/management/business?name=${name}`)
        .then((res) => {
          this.searching = false
          this.suggestionList = []
          if (res.status === 200) {
            // console.log(this.suggestionList)
            this.suggestionList = res.data
          }
        })
        .catch((err) => {
          this.searching = false
          Promise.reject(err)
          this.$notify('获取公司信息失败')
        })
    },
    swiperCellArrowClick(name) {
      this.editing = !this.editing
    },
    // 触发删除
    deleteMe() {
      const payload = {
        index: this.index,
        type: this.type.enum,
      }
      if (this.isWtr) {
        this.$emit('resetDefaultWtr')
      }
      this.$emit('deletePerson', payload)
    },
    // 删除当事人
    onDeletePerson(clickPosition, instance) {
      switch (clickPosition) {
        case 'left':
        case 'cell':
        case 'outside':
          instance.close()
          break
        case 'right':
          this.$dialog
            .confirm({
              message: '确定删除吗？',
            })
            .then(() => {
              this.deleteMe()
              instance.close()
            })
          break
      }
    },
    onFocus() {
      this.showSuggestion = true
      // 展开详细项
      this.editing = true
    },
    onBlur() {
      setTimeout(() => {
        this.showSuggestion = false
      }, 100)
    },
    // 选择搜索建议
    select(person) {
      console.log('pserson', person)
      const { procedureSubjectShort, companyCode, address, companyName: name, faRen: manager, creditCode: idNumber } = person
      // 触发表单组件watch
      this.personActiveType = undefined
      if (procedureSubjectShort) {
        this.person = person.litigant
        this.name = this.person.name
        this.personActiveType = this.person.category
      } else if (companyCode) {
        const p = this.person
        p.address = address
        p.name = name
        // p.phone = phone
        p.manager = manager
        // p.position = position
        this.name = this.person.name
        this.person.idNumber = idNumber
        this.person = p
        this.personActiveType = 'LEGAL_ENTITY'
        // this.getCreditCode(person.companyName)
      }
    },
    getCreditCode(key) {
      this.$axios
        .get(`${caseBase}/management/getInfoByKey?companyName=${key}`)
        .then((res) => {
          if (res.status === 200) {
            const { address, phone, companyName: name, faRen: manager, legalPersonPosition: position, creditCode: idNumber } = res.data
            const p = this.person
            p.address = address
            p.name = name
            p.phone = phone
            p.manager = manager
            p.position = position
            this.name = this.person.name
            this.person.idNumber = idNumber
            this.person = p
            this.personActiveType = 'LEGAL_ENTITY'
          }
        })
        .catch((err) => {
          Promise.reject(err)
          this.$notify('获取公司信息失败 请重试')
        })
    },
    // 清除输入
    clearName() {
      this.name = ''
    },
    // 是否要添加默认代理人
    handleSetWtr(w) {
      this.isWtr = w
    },
    // 获取当事人数据并返回
    getData() {
      try {
        const f = this.$refs.personForm.getData()
        if (f || this.isWtr === 'YES') {
          // 是否为委托人
          const displayClient = this.isWtr ? 'YES' : 'NO'
          // 当事人身份对象
          const subject = this.type
          if (this.personActiveType === 'LEGAL_ENTITY') {
            f.representative = 'LEGAL_REPRESENTATIVE'
          } else if (this.personActiveType === 'NON_LEGAL_ENTITY') {
            f.representative = 'LEGAL_ENTITY_PRINCIPAL'
          } else {
            f.representative = null
          }
          if (!f.sex) {
            f.sex = 'UNKNOWN'
          }
          f.name = this.name
          f.category = this.name === '' ? null : this.personActiveType
          f.displayClient = displayClient
          f.procedureSubject = subject.enum
          f.procedureSubjectKeyId = subject.attrKeyId
          f.procedureSubjectValueId = subject.attrValueId
          return f
        } else {
          return null
        }
      } catch (err) {
        console.log('personItem的getData错误', err)
      }
    },
    // 手机号错误提示
    handlePhoneErr(payload) {
      const name = this.name ? this.name : '(未填写名称)'
      const alert = `${name}: ${payload} 不正确`
      this.$notify(alert)
    },
  },
  created() {
    console.log(this.$route.params)
    const p = this.personData
    p.companyName = this.name || ''
    if (this.personData.name) {
      this.name = this.personData.name
      this.shouldSearchFirst = false
    }
    if (this.personData.displayClient === 'YES') {
      this.isWtr = true
    }
    // 设置代理人类型
    this.personActiveType = this.personData.category || 'NATURAL_PERSON'
    this.person = p
    setTimeout(() => {
      this.shouldSearchFirst = true
    }, 100)
  },
  watch: {
    name(nv, ov) {
      if (this.name !== '' && this.name.length >= 2 && this.shouldSearchFirst) {
        this.search()
      }
      this.$emit('pass', this.name)
    },
    idTypepass() {
      this.$emit('passid', this.idTypepass)
    },
    isWtr(nv, ov) {
      this.personData.displayClient = nv ? 'YES' : 'NO'
      this.$emit('passisTrue', this.isWtr)
    },
  },
}
</script>

<style lang="stylus" scoped>
.title-with-switch
  display flex
  align-items center
  position relative
  .name-input
    width calc(100% - 10px)
  .name-input-clear
    color #999
    font-size 16px
    line-height 18px
    position absolute
    right 10px
.search-suggestion
  position absolute
  width calc(100% - 20px)
  background #ffffff
  max-height 240px
  overflow-y auto
  box-shadow 0px 1px 8px 1px rgba(64, 180, 233, 0.23)
  border-radius 0 0 4px 4px
  z-index 10
  .van-loading
    margin 0 auto
  .suggestion-list-item
    display flex
    justify-content space-between
    align-items center
    padding 8px 18px
    .name
      font-size 14px
      color #555
    .attr
      font-size 13px
      color #888
    .attr.tag
      background #3EB3F0
      color #fff
      padding 0 2px
      border-radius 2px
.collapse-item__wrapper
  transition height 0.3s ease-in-out
  overflow hidden
  will-change height
  overflow-y auto
  background #F0F6FD
  .collapse-item__content
    padding 0 0.2rem 0.2rem 0.2rem
    background #F0F6FD
    .id-type
      .van-collapse
        .van-collapse-item
          .van-collapse-item__title
            background transparent
.id-type
  .van-collapse
    .id-type-cell.van-collapse-item
      .van-cell
        border-bottom 0.026667rem solid #d2d2d2
        .van-cell__value
          span
            color #666
</style>
