export default {
  litigantId: '',
  procedureSubject: '',
  procedureSubjectKeyId: '',
  procedureSubjectValueId: '',
  category: '',
  displayClient: '',
  // 法人或非法人单位名称 或自然人姓名
  name: '',
  sex: '',
  nation: '',
  idNumber: '',
  phone: '',
  address: '',
  selfColumnName: '',
  selfColumnValue: '',
  memo: '',
  profession: '',
  workOffice: '',
  // 管理者姓名
  manager: '',
  // 职务
  position: '',
  longResidence: ''
}
