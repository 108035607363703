var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "fine-switch" }, [
    _c(
      "div",
      {
        class: { wrapper: true, on: _vm.active },
        style: _vm.switchStyle,
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.change.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "node" }),
        _c("div", { staticClass: "text" }, [_vm._v(_vm._s(_vm.switchText))]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }