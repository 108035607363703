var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "agent-item-wrapper" },
    [
      _c(
        "van-action-sheet",
        {
          staticClass: "action-sheet",
          attrs: { "get-container": "body" },
          model: {
            value: _vm.showAgentPicker,
            callback: function ($$v) {
              _vm.showAgentPicker = $$v
            },
            expression: "showAgentPicker",
          },
        },
        [
          _c("van-picker", {
            attrs: {
              columns: _vm.agentList,
              "item-height": _vm.normalPickerItemHeight,
              "show-toolbar": "",
              title: "选择代理人",
              "visible-item-count": 5,
              loading: _vm.isLoadingAgent,
            },
            on: { cancel: _vm.onAgentCancel, confirm: _vm.onAgentConfirm },
          }),
        ],
        1
      ),
      _c(
        "van-action-sheet",
        {
          staticClass: "action-sheet",
          attrs: { "get-container": "body" },
          model: {
            value: _vm.showAgencyTypePicker,
            callback: function ($$v) {
              _vm.showAgencyTypePicker = $$v
            },
            expression: "showAgencyTypePicker",
          },
        },
        [
          _c("van-picker", {
            attrs: {
              columns: _vm.clientTypeList,
              "item-height": _vm.normalPickerItemHeight,
              "show-toolbar": "",
              title: "选择代理权限",
              "visible-item-count": 3,
            },
            on: {
              cancel: _vm.onAgencyTypeCancel,
              confirm: _vm.onAgencyTypeConfirm,
            },
          }),
        ],
        1
      ),
      _c(
        "van-action-sheet",
        {
          staticClass: "action-sheet",
          attrs: { "get-container": "body" },
          model: {
            value: _vm.isShowAgencyTypePicker,
            callback: function ($$v) {
              _vm.isShowAgencyTypePicker = $$v
            },
            expression: "isShowAgencyTypePicker",
          },
        },
        [
          _c("van-picker", {
            attrs: {
              columns: _vm.agencyTypeList,
              "item-height": _vm.normalPickerItemHeight,
              "show-toolbar": "",
              title: "选择代理人类型",
              "visible-item-count": 5,
            },
            on: {
              cancel: function ($event) {
                _vm.isShowAgencyTypePicker = false
              },
              confirm: _vm.onTypeConfirm,
            },
          }),
        ],
        1
      ),
      _c(
        "van-action-sheet",
        {
          staticClass: "action-sheet",
          attrs: { "get-container": "body" },
          model: {
            value: _vm.showNationPicker,
            callback: function ($$v) {
              _vm.showNationPicker = $$v
            },
            expression: "showNationPicker",
          },
        },
        [
          _c("van-picker", {
            attrs: {
              columns: _vm.nationList,
              "item-height": _vm.normalPickerItemHeight,
              "show-toolbar": "",
              title: "选择民族",
              "visible-item-count": 5,
            },
            on: {
              cancel: function ($event) {
                _vm.showNationPicker = false
              },
              confirm: _vm.onNationConfirm,
            },
          }),
        ],
        1
      ),
      _c(
        "van-popup",
        {
          staticClass: "agent-pop",
          attrs: {
            position: "bottom",
            "get-container": "body",
            "close-on-click-overlay": _vm.laweroffice ? false : true,
          },
          model: {
            value: _vm.showAgentPop,
            callback: function ($$v) {
              _vm.showAgentPop = $$v
            },
            expression: "showAgentPop",
          },
        },
        [
          _c(
            "van-cell-group",
            { staticClass: "text-right" },
            [
              _c("van-cell", {
                attrs: {
                  title: "代理人类型",
                  "is-link": "",
                  value: _vm.agencyTypeName || "请选择代理人类型",
                },
                on: {
                  click: function ($event) {
                    _vm.isShowAgencyTypePicker = true
                  },
                },
              }),
              _c(
                "van-cell",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isShowLawFirm,
                      expression: "isShowLawFirm",
                    },
                  ],
                  staticClass: "lawfirm-cell",
                },
                [
                  _c("template", { slot: "title" }, [
                    _c("div", { staticClass: "title-with-switch" }, [
                      _c("div", { staticClass: "label" }, [
                        _vm._v("代理人单位"),
                      ]),
                      _c("div", { staticClass: "input" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.agent.lawFirmName,
                              expression: "agent.lawFirmName",
                            },
                          ],
                          staticClass: "name-input",
                          attrs: { placeholder: "代理人单位" },
                          domProps: { value: _vm.agent.lawFirmName },
                          on: {
                            focus: _vm.onFocus,
                            blur: _vm.onBlur,
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.agent,
                                "lawFirmName",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                  ]),
                ],
                2
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showSuggestion,
                      expression: "showSuggestion",
                    },
                  ],
                  staticClass: "search-suggestion",
                },
                [
                  _c("van-loading", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.searching,
                        expression: "searching",
                      },
                    ],
                  }),
                  _vm._l(_vm.lawFirmList, function (p) {
                    return _c(
                      "div",
                      {
                        key: p.id,
                        staticClass: "suggestion-list-item",
                        on: {
                          click: function ($event) {
                            return _vm.selectLawFirm(p)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "name" }, [
                          _vm._v(_vm._s(p.name)),
                        ]),
                        _c("div", { staticClass: "attr" }),
                      ]
                    )
                  }),
                ],
                2
              ),
              _c("van-field", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isVisiable,
                    expression: "isVisiable",
                  },
                ],
                attrs: { label: "姓名", placeholder: "请填写代理人姓名" },
                model: {
                  value: _vm.agent.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.agent, "name", $$v)
                  },
                  expression: "agent.name",
                },
              }),
              _c("van-cell", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isVisiable && _vm.agent.lawFirmName,
                    expression: "!isVisiable && agent.lawFirmName",
                  },
                ],
                attrs: {
                  title: "代理人姓名",
                  "is-link": "",
                  value: _vm.agent.name || "请选择代理人",
                },
                on: { click: _vm.pickAgent },
              }),
              _c("div", { staticClass: "form-wrapper" }, [
                _c(
                  "div",
                  { staticClass: "form-item" },
                  [
                    _c("div", { staticClass: "label" }, [_vm._v("代理权限")]),
                    _c(
                      "van-radio-group",
                      {
                        model: {
                          value: _vm.agent.agencyAuthority,
                          callback: function ($$v) {
                            _vm.$set(_vm.agent, "agencyAuthority", $$v)
                          },
                          expression: "agent.agencyAuthority",
                        },
                      },
                      [
                        _c("van-radio", { attrs: { name: "COMMONLY_AGENT" } }, [
                          _vm._v("一般授权"),
                        ]),
                        _c("van-radio", { attrs: { name: "SPECIAL_AGENT" } }, [
                          _vm._v("特别授权"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isVisiable,
                      expression: "isVisiable",
                    },
                  ],
                  staticClass: "other-form",
                },
                [
                  _c("van-field", {
                    attrs: {
                      label: "与委托人关系",
                      placeholder: "与委托人关系",
                    },
                    model: {
                      value: _vm.agent.relationship,
                      callback: function ($$v) {
                        _vm.$set(_vm.agent, "relationship", $$v)
                      },
                      expression: "agent.relationship",
                    },
                  }),
                  _c("van-field", {
                    attrs: { label: "身份证号", placeholder: "请输入身份证号" },
                    model: {
                      value: _vm.agent.idNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.agent, "idNumber", $$v)
                      },
                      expression: "agent.idNumber",
                    },
                  }),
                  _c("van-field", {
                    attrs: { label: "联系电话", placeholder: "请输入联系电话" },
                    model: {
                      value: _vm.agent.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.agent, "phone", $$v)
                      },
                      expression: "agent.phone",
                    },
                  }),
                  _c("div", { staticClass: "form-wrapper" }, [
                    _c(
                      "div",
                      { staticClass: "form-item" },
                      [
                        _c("div", { staticClass: "label" }, [_vm._v("性别")]),
                        _c(
                          "van-radio-group",
                          {
                            model: {
                              value: _vm.agent.sex,
                              callback: function ($$v) {
                                _vm.$set(_vm.agent, "sex", $$v)
                              },
                              expression: "agent.sex",
                            },
                          },
                          [
                            _c("van-radio", { attrs: { name: "MALE" } }, [
                              _vm._v("男"),
                            ]),
                            _c("van-radio", { attrs: { name: "FEMALE" } }, [
                              _vm._v("女"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("van-cell", {
                    attrs: {
                      title: "民族",
                      value: _vm.agent.nation || "请选择民族",
                      "is-link": "",
                    },
                    on: { click: _vm.pickNation },
                  }),
                  _c("van-field", {
                    attrs: { label: "住所", placeholder: "请输入住所" },
                    model: {
                      value: _vm.agent.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.agent, "address", $$v)
                      },
                      expression: "agent.address",
                    },
                  }),
                  _c("van-field", {
                    attrs: { label: "工作单位", placeholder: "请输入工作单位" },
                    model: {
                      value: _vm.agent.workOffice,
                      callback: function ($$v) {
                        _vm.$set(_vm.agent, "workOffice", $$v)
                      },
                      expression: "agent.workOffice",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "foot" }, [
            _c("div", { staticClass: "confirm", on: { click: _vm.hideshow } }, [
              _vm._v("确定"),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "van-swipe-cell",
        {
          staticClass: "agent-swiper-cell",
          attrs: { "right-width": _vm.swipeCellRightWidth },
        },
        [
          _c(
            "van-cell",
            [
              _c("template", { slot: "title" }, [
                _c("div", { staticClass: "agent-item" }, [
                  _c("div", { staticClass: "name" }, [
                    _vm._v(_vm._s(_vm.agentName)),
                  ]),
                  _c("div", { staticClass: "AgencyType" }, [
                    _vm._v(_vm._s(_vm.clientTypeName || "选择代理权限")),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "action",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.editAgent.apply(null, arguments)
                        },
                      },
                    },
                    [_c("i", { staticClass: "iconfont icon-edit" })]
                  ),
                ]),
              ]),
            ],
            2
          ),
          _c(
            "span",
            {
              attrs: { slot: "right" },
              on: {
                click: function ($event) {
                  return _vm.deleteMe("1")
                },
              },
              slot: "right",
            },
            [_vm._v("删除")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }