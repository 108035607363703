export const paramCode = {
  getTrialGradeName(level) {
    switch (level) {
      case 10014:
        return '一审'
      case 10015:
        return '二审'
      case 10016:
        return '再审'
      case 12064:
        return '执行'
      case 13272:
        return '侦查阶段'
      case 13273:
        return '审查起诉'
      default:
        break
    }
  },
  getProcedureName(produce) {
    switch (produce) {
      case 10020:
        return '简易程序'
      case 10021:
        return '普通程序'
      case 10022:
        return '特别程序'
      case 12062:
        return '执行程序'
      case 12063:
        return '小额诉讼'
      case 13272:
        return '侦查程序'
      case 13273:
        return '审查程序'
      case 10014:
        return '一审程序'
      case 10015:
        return '二审程序'
      case 10016:
        return '再审程序'
      default:
        break
    }
  },
  firstCaseReason(index) {
    switch (index) {
      case 10020:
        return '简易程序'
      case 10021:
        return '普通程序'
      case 10022:
        return '特别程序'
      case 12062:
        return '执行程序'
      case 12063:
        return '小额诉讼'
      case 13272:
        return '侦查程序'
      case 13273:
        return '审查程序'
      case 10014:
        return '一审程序'
      case 10015:
        return '二审程序'
      case 10016:
        return '再审程序'
      default:
        break
    }
  },
  getLitigantIdentity(type) {
    switch (type) {
      case 10020:
        return '简易程序'
      case 10021:
        return '普通程序'
      case 10022:
        return '特别程序'
      case 12062:
        return '执行程序'
      case 12063:
        return '小额诉讼'
      case 13272:
        return '侦查程序'
      case 13273:
        return '审查程序'
      case 10014:
        return '一审程序'
      case 10015:
        return '二审程序'
      case 10016:
        return '再审程序'
      default:
        break
    }
  },
  category(type) {
    switch (type) {
      case 'LEGAL_REPRESENTATIVE':
        return '法定代表人'
      case 'LEGAL_ENTITY_PRINCIPAL':
        return '主要负责人'
      case 'UNKNOWN':
        return '未知'
      default:
        break
    }
  },
  sex(type) {
    switch (type) {
      case 'UNKNOWN':
        return '未知'
      case 'MALE':
        return '先生'
      case 'FEMALE':
        return '女士'
      default:
        break
    }
  },
  getProcedureSubject(type) {
    switch (type) {
      case '原告':
        return {
          checked: 0,
          code: 0,
          color: 'no',
          enum: 'PLAINTIFF',
          name: '原告',
          attrKeyId: 10006,
          attrValueId: 10017
        }
      case '被告':
        return {
          checked: 0,
          color: 'no',
          code: 1,
          enum: 'DEFENDANT',
          name: '被告',
          attrKeyId: 10006,
          attrValueId: 10018
        }
      case '第三人':
        return {
          checked: 0,
          color: 'no',
          code: 2,
          enum: 'THIRD_PARTY',
          name: '第三人',
          attrKeyId: 10006,
          attrValueId: 10019
        }
      case '案外人':
        return {
          checked: 0,
          color: 'no',
          code: 3,
          enum: 'NOT_INVOLVED_CASE',
          name: '案外人',
          attrKeyId: 10006,
          attrValueId: 13249
        }
      default:
        break
    }
  },
  procedureSubject(type) {
    switch (type) {
      case 'PLAINTIFF':
        return '原告'
      case 'DEFENDANT':
        return '被告'
      case 'THIRD_PARTY':
        return '第三人'
      case 'NOT_INVOLVED_CASE':
        return '案外人'
      case 'APPELLANT':
        return '上诉人'
      case 'APPELLEE':
        return '被上诉人'
      case 'FIRST_TRIAL_PLAINTIFF':
        return '原审原告'
      case 'FIRST_TRIAL_THIRD_PARTY':
        return '原审第三人'
      case 'FIRST_TRIAL_DEFENDANT':
        return '原审被告'
      case 'GRIEVANT':
        return '申诉人'
      case 'RESPONDENT':
        return '被申诉人'
      case 'APPLICANT':
        return '申请人'
      case 'CLAIMANT':
        return '被申请人'
      case 'DEFENDANT_PEOPLE':
        return '被告人'
      case 'CRIMINAL_SUSPECT':
        return '嫌疑人'
      case 'DRIVER':
        return '驾驶人'
      case 'CAR_OWNER':
        return '车主'
      case 'VICTIM':
        return '受害人'
      case 'INSURANCE_COMPANY':
        return '保险公司'
      case 'PASSENGER':
        return '乘车人'
      case 'EXECUTION_APPLICANT':
        return '申请执行人'
      case 'EXECUTEE':
        return '被执行人'
      default:
        break
    }
  },
  agencyType(type) {
    switch (type) {
      case 'LAWYER':
        return '律师'
      case 'OTHER_NATURAL_PEOPLE':
        return '其他自然人'
      case 'LEGAL_WORKER':
        return '法律工作者'
      case 'LEGAL_AGENT':
        return '法定代理人'
      case 'LEGAL_REPRESENTATIVE':
        return '法定代表人'
      case 'PERSON_IN_CHARGE':
        return '负责人（机构)'
      default:
        break
    }
  },
  clientType(type) {
    switch (type) {
      case 'COMMONLY_AGENT':
        return '一般授权'
      case 'SPECIAL_AGENT':
        return '特别授权'
      default:
        break
    }
  },
  gender(type) {
    switch (type) {
      case 'UNKNOWN':
        return '未知'
      case 'MALE':
        return '先生'
      case 'FEMALE':
        return '女士'
      default:
        break
    }
  },
  categoryIndex(index) {
    switch (index) {
      case 0:
        return '自然人'
      case 1:
        return '法人单位'
      case 2:
        return '非法人单位'
      default:
        break
    }
  },
  getCategoryIndex(index) {
    switch (index) {
      case 'NATURAL_PERSON':
        return 0
      case 'LEGAL_ENTITY':
        return 1
      case 'NON_LEGAL_ENTITY':
        return 2
      default:
        break
    }
  }
}
